import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, CartTypes} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Checkout extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      email: "",
      senha: "",
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
    }
  }

  componentDidMount () {
    const self = this; 
    // if (( self.props.user ) && ( self.props.user.status === true )) { // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
    //   self.setState({redirect: '/'});
    // }
  }

  private getCartTotal () {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < self.props.cart.produtos.length; i++ ) {
      total += self.props.cart.produtos[i].price * self.props.cart.produtos[i].qty;
    }
    return total;
  }

  private handleDropProduto (item: any) {
    const self = this;

    if ( !item )
      return;

    let confirmDrop = window.confirm(`Deseja realmente excluir o produto ${item.nome} do seu carrinho?`);

    if ( confirmDrop )
      self.props.drop(item.rowid);
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header tipo={2}/>

        <section className="content-fluid w-75 mx-auto mb-5">
          <h1>Checkout</h1>
          {
            (this.props.cart.produtos.length <= 0) ? 
            <>
              <Row> 
                <Col>
                  <div className="alert alert-info">Seu carrinho está vazio. Navegue por nossa loja e encontre
                  seus produtos favoritos! </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <a href="/" target="_self" className="btn btn-link keep-shopping">
                    <FontAwesomeIcon icon={faArrowLeft} className="cart-item-action keep-shopping mr-2 text-link" /> 
                    Continuar comprando
                  </a>
                </Col>
              </Row>
            </>
            :
            <>
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Produto</th>
                    <th className="text-center">Preço</th>
                    <th className="text-center">Quantidade</th>
                    <th className="text-center">Subtotal</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {
                    this.props.cart.produtos.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.nome}</td>
                        <td className="text-center">R$ {Diversos.number_format(row.price,2,",","")}</td>
                        <td className="text-center">{row.qty}</td>
                        <td className="text-center">R$ {Diversos.number_format((row.price * row.qty),2,",","")}</td>
                        <td className="text-right">
                          <Button variant="danger" size={"sm"} onClick={this.handleDropProduto.bind(this, row)}>
                            <FontAwesomeIcon icon={faTrashAlt} className="cart-item-action" title="Excluir item" /> 
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>

              <Table responsive="sm">
                <tr>
                  <th colSpan={6} className="text-right">TOTAL</th>
                  <td className="text-center">R$ {Diversos.number_format(this.getCartTotal(),2,",","")}</td>
                </tr>
              </Table>

              <Row>
                <Col>
                  <a href="/" target="_self" className="btn btn-link">
                    <FontAwesomeIcon icon={faArrowLeft} className="cart-item-action mr-2 text-link" /> 
                    Continuar comprando
                  </a>
                </Col>
                <Col className="text-right">
                  <a href={(( this.props.user ) && ( this.props.user.status === true )) ? `/checkout/entrega` : `/login`} className="btn btn-success btn-lg text-white">
                    <FontAwesomeIcon icon={faShoppingCart} className="cart-item-action mr-2 text-white" /> 
                    Finalizar compra
                  </a>
                </Col>
              </Row>
            </>
          }
        </section>
        
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
