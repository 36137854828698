import React from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import { Redirect } from "react-router-dom";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';

import Nav from 'react-bootstrap/Nav'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faLock, faEnvelope, faReceipt, faSyncAlt, faSignOutAlt, faCommentAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


interface StateProps { 
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Central extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null
    }
  }

  private async handleLogout () {
    const self = this;
    self.props.doLogout();
    self.setState({redirect: "/"});
  }


  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header/>

        <section className="central-do-cliente">
          <h1> Central do Cliente </h1>
          <p> Olá Generoso! Seja bem vindo(a) à Central do Cliente. </p>
          <Nav className="central-nav" as="nav" variant="pills" justify={false}>
            <Nav.Item>
              <Nav.Link className="central-controller" href="/meu-perfil" eventKey="link-0"> 
                <h5> Meu Perfil </h5>
                <FontAwesomeIcon icon={faUserEdit}/>
                <p> Consulte ou altere seus dados </p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="central-controller" href="/meus-pedidos" eventKey="link-1">
                <h5> Meus pedidos </h5>
                <FontAwesomeIcon icon={faReceipt}/>
                <p> Confira seus pedidos realizados </p>
              </Nav.Link>
            </Nav.Item>
            {/*<Nav.Item>*/}
            {/*  <Nav.Link className="central-controller" href="/reset-senha" eventKey="link-2">*/}
            {/*    <h5> Reset de senha </h5>*/}
            {/*    <FontAwesomeIcon icon={faLock}/>*/}
            {/*    <p> Troque sua senha de acesso atual </p>*/}
            {/*  </Nav.Link>*/}
            {/*</Nav.Item>*/}
            <Nav.Item>
              <Nav.Link className="central-controller" href="/contato" eventKey="link-3">
                <h5> Fale Conosco </h5>
                <FontAwesomeIcon icon={faEnvelope}/>
                <p> Entre em contato </p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="central-controller" href="/trocas-e-devolucoes" eventKey="link-4">
                <h5> Trocas & Devoluções </h5>
                <FontAwesomeIcon icon={faSyncAlt}/>
                <p> Confira nossa política de trocas e devoluções </p>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="central-content">
            <aside>
              <a href="https://api.whatsapp.com/send?phone=5541988026971&text=Ol%C3%A1%20poderia%20me%20ajudar%3F"
              target="_blank"
              rel="noopener noreferrer"
              title="Inicar conversa por Whatsapp">
                <span>
                  <FontAwesomeIcon icon={faWhatsapp} mask={faCommentAlt} transform="shrink-7 up-1.5" className="central-contato"/>
                  <p> Inicie uma conversa <br/> conosco pelo Whatsapp! </p>
                </span>
                <small> Clique para ver </small>
              </a>
              <a href="https://g.page/Dricor?share"
              target="_blank"
              rel="noopener noreferrer"
              title="Ver localização no Google Maps">
                <span>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="central-contato"/>
                  <p> Venha nos fazer <br/> uma visita! </p>
                </span>
                <small> Clique para ver </small>
              </a>
            </aside>
            <button className="btn-logout" onClick={this.handleLogout}> <FontAwesomeIcon icon={faSignOutAlt}/> Fazer Logout </button>
          </div>
        </section>

        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Central);
