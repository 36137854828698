import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import InputMask from "react-input-mask";
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, CartTypes} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {OrderType, OrderTypes, ItemType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Table, Button, Row, Col, Card, Accordion, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMapMarkerAlt, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import CreditCard from '@bit/vitorbarbosa19.ziro.credit-card';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import './index.scss';

import processingGif from '../../assets/animations/processing.json'
import noBrand from '../../assets/Images/card-brand.png';
import visa from '../../assets/Images/visa.png';
import master from '../../assets/Images/mastercard.png';
import amex from '../../assets/Images/amex.png';
declare var window: any;

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  setOrder(data: OrderType): void;
  clean(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class CheckoutPagamento extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      isLoadingCustomer: true,
      hasErrorCustomer: false,
      hasErrorTitleCustomer: "",
      hasErrorMsgCustomer: "",
      customer: null,

      isLoadingFrete: false,
      hasErrorFrete: false,
      hasErrorTitleFrete: "",
      hasErrorMsgFrete: "",
      freteOpcoes: [],

      formFormaEntregaCodigo: null,
      formFormaEntregaPreco: null,
      formFormaEntregaPrazo: null,
      formFormaEntregaNome: null,

      formFormaPgtoCodigo: 1,

      formCartaoNumero: "",
      formCartaoNome: "",
      formCartaoValidadeMes: "",
      formCartaoValidadeAno: "",
      formCartaoCvv: "",
      formCartaoCvvSize: "",
      formCartaoBandeira: "",

      formIsLoading: false,
      formHasError: false,
      formHasErrorTitle: "",
      formHasErrorMsg: "",

      pagseguroSenderHash: null,
    }
  }

  componentDidMount () {
    const self = this; 
    if (( !self.props.user ) || ( self.props.user.status === false )) { 
      self.setState({redirect: '/login'});
    }

    if (( !self.props.cart.produtos ) || ( self.props.cart.produtos.length <= 0 )) {
      self.setState({redirect: '/checkout'});
    }

    self.getCustomer();
    self.getPagseguroSessionId();
  }

  private async getPagseguroSessionId () {
    const self = this;
    try {
      const {data} = await self.api.get(`/order/pagseguro/session`);
      
      if ( data.status === true ) {
        window.PagSeguroDirectPayment.setSessionId( data.msg );
        window.PagSeguroDirectPayment.onSenderHashReady(function(response){
          
          if(response.status == 'error') {
            console.error(`Falha PagSeguro::onSenderHashReady: ${response.message}`);
            return false;
          }
          var hash = response.senderHash; //Hash estará disponível nesta variável.
          self.setState({pagseguroSenderHash: hash});
        });
      }

    } catch (e) {
      console.error(`Falha ao capturar session id PagSeguro: `, e);
    }
  }

  private async getCustomer () {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status == false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
        self.getShippingModes();
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        hasErrorCustomer: true,
        hasErrorTitleCustomer: 'Cadastro não localizado',
        hasErrorMsgCustomer: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async getShippingModes () {
    const self = this;

    self.setState({isLoadingFrete: true});

    try {
      let param = {cep: self.state.customer.cep};
      const {data} = await self.api.post(`/shipping/modes`, param);

      if ( data.status == false ) {
        throw new Error('Não foi possível buscar opções de entrega.');
      } else {
        self.setState({freteOpcoes: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        freteOpcoes: null,
        hasErrorFrete: true,
        hasErrorTitleFrete: 'Cadastro não localizado',
        hasErrorMsgFrete: e.message
      });

    } finally {
      self.setState({isLoadingFrete: false});
    }
  }

  private handleChangeFreteModo (nome) {
    const self = this;

    if ( nome ) {
      for (var i = 0; i < self.state.freteOpcoes.length; i++) {
        if (  self.state.freteOpcoes[i].nome === nome ) {
          self.setState({
            formFormaEntregaCodigo: self.state.freteOpcoes[i].codigo,
            formFormaEntregaNome: self.state.freteOpcoes[i].nome,
            formFormaEntregaPreco: self.state.freteOpcoes[i].preco,
            formFormaEntregaPrazo: self.state.freteOpcoes[i].prazo
          });
          break;
        }
      }
    }

  }

  private async getCardBrand ( cardBin ) {
    const self = this;

    let param = {bin: cardBin};

    try {
      const {data} = await self.api.post('/order/card/brand', param);

      if ( data.status !== true ) 
        throw new Error('Não foi possível buscar bandeira do cartão');
      else
        this.setState({
          formCartaoBandeira: data.msg.brand.name,
          formCartaoCvvSize: parseInt(data.msg.cvvSize),
        });
    } catch (e) {
      console.error(`Falha na busca da bandeira do cartao:`, e);
    }
  }

  private getCardBrandImg () {
    const self = this;
    switch ( self.state.formCartaoBandeira ) {
      case "visa":
        return visa;
      case "mastercard":
        return master;
      case "amex":
        return amex;
      default:
        return noBrand;
    }
  }

  private getCartTotal () {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < self.props.cart.produtos.length; i++ ) {
      total += self.props.cart.produtos[i].price * self.props.cart.produtos[i].qty;
    }
    return total;
  }

  private async handleForm (event) {
    const self = this;

    event.preventDefault();

    if ( self.getCartTotal() <= 0 ) {
      self.setState({ formIsLoading: false, formHasError: true, formHasErrorMsg: "Valor total do carrinho inválido." });
      return 0;
    }

    if ( self.props.cart.produtos.length <= 0 ) {
      self.setState({ formIsLoading: false, formHasError: true, formHasErrorMsg: "Seu carrinho está vazio." });
      return 0;
    }

    if (( !self.state.formFormaEntregaCodigo ) || ( !self.state.formFormaEntregaNome )) {
      self.setState({ formIsLoading: false, formHasError: true, formHasErrorMsg: "Forma de entrega não selecionada." });
      return 0;
    }

    if (( !self.state.customer.rua ) || ( !self.state.customer.cep ) || ( !self.state.customer.cidade )) {
      self.setState({ formIsLoading: false, formHasError: true, formHasErrorMsg: "Endereço de entrega não é válido." });
      return 0;
    }

    if (( self.state.formFormaPgtoCodigo < 1 ) || ( self.state.formFormaPgtoCodigo > 2 )) {
      self.setState({ formIsLoading: false, formHasError: true, formHasErrorMsg: "Forma de pagamento selecionada não é válida." });
      return 0;
    }

    if ( self.state.formFormaPgtoCodigo == 1 ) {
      if (( !self.state.formCartaoNumero ) || ( !self.state.formCartaoNome ) || ( !self.state.formCartaoValidadeAno ) || ( !self.state.formCartaoValidadeMes ) || ( !self.state.formCartaoCvv )) {
        self.setState({ formIsLoading: false, formHasError: true, formHasErrorMsg: "Dados do cartão estão incompleto." });
        return 0;
      }
    }

    self.setState({formIsLoading: true});

    let param = {
      cliente: self.state.customer.codigo,
      items: [],
      formapg: self.state.formFormaPgtoCodigo,
      total: self.getCartTotal() + self.state.formFormaEntregaPreco,
      frete: {
        servico: self.state.formFormaEntregaNome,
        prazo: self.state.formFormaEntregaPrazo,
        valor: self.state.formFormaEntregaPreco
      },
      cartao: [{
        numero: "",
        validade: "",
        cvv: "",
        parcelas: 1,
        valor: self.getCartTotal() + self.state.formFormaEntregaPreco
      }],
      pagseguroSenderHash: self.state.pagseguroSenderHash
    }

    if ( self.state.formFormaPgtoCodigo == 1 ) {
      param.cartao[0].numero = Diversos.getnums(self.state.formCartaoNumero);
      param.cartao[0].validade = `${self.state.formCartaoValidadeMes}/${self.state.formCartaoValidadeAno}`;
      param.cartao[0].cvv = Diversos.getnums(self.state.formCartaoCvv);
    }

    let tmpProdutos: any = [];
    for (var i = 0; i < self.props.cart.produtos.length; i++) {
      tmpProdutos.push({
        codigo: self.props.cart.produtos[i].codigo,
        quantidade: self.props.cart.produtos[i].qty,
        valor: self.props.cart.produtos[i].price
      });
    }
    param.items = tmpProdutos;

    try {
      const {data} = await self.api.post("/order", param);

      if ( data.status !== true ) {
        throw new Error('Não foi possível processar seu pedido.');
      } else {

        let tmpProdu: Array<ItemType> = [];
        for ( var i = 0; i < data.msg.itens.length; i++ ) {
          tmpProdu.push({
            produto: data.msg.itens[i].produto,
            valor: data.msg.itens[i].valor,
            qtd: data.msg.itens[i].qtd,
            nome: data.msg.itens[i].nome,
          });
        }

        await self.props.setOrder({
          cliente: data.msg.cliente,
          data: data.msg.data,
          status: data.msg.status,
          entrega: data.msg.entrega,
          dtentrega: data.msg.dtentrega,
          frete: data.msg.frete,
          formapg: data.msg.formapg,
          hora: data.msg.hora,
          pedido: data.msg.PEDIDO,
          boleto_url: data.msg.BOLETO_URL,
          itens: tmpProdu,
          cartao: {
            numero: self.state.formCartaoNumero.substring(self.state.formCartaoNumero.length - 4),
            bandeira: self.state.formCartaoBandeira,
          }
        });
        
        await self.props.clean(); // limpa carrinho

        setTimeout(() => {
          window.location.href = "/checkout/fim";
        }, 200);
      }

    } catch (e) {
      console.error(e);
      self.setState({});
      self.setState({formHasError: true, formHasErrorMsg: e.message, formIsLoading: false});
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header tipo={2}/>

        <section className="content-fluid w-100 mx-auto mb-5">
          <h1 className="mb-5">Checkout - Pagamento</h1>

          {
            this.state.formHasError &&
            <Row>
              <Col>
                <div className={'alert alert-danger'}>
                  <FontAwesomeIcon icon="times" className="mr-2"/>
                  <strong>{this.state.formHasErrorTitle}</strong>
                  <p>{this.state.formHasErrorMsg}</p>
                </div>
              </Col>
            </Row>
          }
          
          {
            (this.state.hasError) ?
            <Row>
              <Col>
                <div className="alert alert-danger">
                  <strong>{this.state.hasErrorTitle}</strong> <br/>
                  <p>{this.state.hasErrorMsg}</p>
                </div>
              </Col>
            </Row>
            :
            (this.state.isLoading) ?
            <p>Carregando ...</p>
            :
            <>
              <Row>
                <Col xs={12} lg={4} className="mb-5">
                  <Card className="cart-card-wrapper">
                    <Card.Header> <FontAwesomeIcon icon={faMapMarkerAlt} className="cart-item-action mr-2" /> Endereço de entrega</Card.Header>
                    <Card.Body>
                      {
                        (this.state.isLoadingCustomer) ?
                        <Card.Text className="text-center">
                          {/*<Spinner animation="border" role="status" variant="secondary">*/}
                            <span className="sr-only">Buscando endereço de entrega...</span>
                          {/*</Spinner>*/}
                        </Card.Text>
                        :
                        (!this.state.customer) ? 
                        <div className="alert alert-warning">
                          <FontAwesomeIcon icon="exclamation-circle" className="mr-1" size="sm"/>
                          Endereço de entrega não localizado!
                        </div>
                        :
                        <> 
                          <Card.Text style={{fontSize: 14}}>
                            {`${this.state.customer.rua}, ${this.state.customer.numero}`} <br/>
                            {`${this.state.customer.complemento}`} <br/>
                            {`${this.state.customer.bairro}`} <br/>
                            {`${this.state.customer.cidade} - ${this.state.customer.estado}`} <br/>
                            {`${this.state.customer.cep}`}
                          </Card.Text>
                          <a href="/cadastro" target="_self" className="btn btn-primary">Alterar endereço</a>
                        </>
                      }
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} lg={4} className="mb-5">
                  <Card className="cart-card-wrapper">
                    <Card.Header>
                      <FontAwesomeIcon icon={faTruck} className="cart-item-action mr-2" /> Opções de entrega
                    </Card.Header>
                    <Card.Body>
                      {
                        (this.state.isLoadingFrete) ?
                        <Card.Text className="text-center">
                          <Spinner animation="border" role="status" variant="secondary">
                            <span className="sr-only">Buscando endereço de entrega...</span>
                          </Spinner>
                        </Card.Text>
                        :
                        ((this.state.freteOpcoes==null) ||
                        (this.state.freteOpcoes.length <= 0)) ? 
                        <div className="alert alert-warning">
                          <FontAwesomeIcon icon="exclamation-circle" className="mr-1" size="sm"/>
                          Nenhuma opção de entrega encontrada.
                        </div>
                        :
                        <>
                          <RadioGroup onChange={this.handleChangeFreteModo.bind(this)}>
                          {
                            this.state.freteOpcoes.map((row, index) => (
                              <RadioButton value={row.nome} key={index} padding={10}>
                                <strong>{row.nome}</strong> <br/>
                                <small>
                                  Prazo: {row.prazo} | Preço: R$ {Diversos.number_format(row.preco,2,",","")}
                                </small>
                              </RadioButton>
                            ))
                          }
                          </RadioGroup>
                        </>
                      }
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} lg={4} className="mb-5">
                  <Card className="cart-card-wrapper">
                    <Card.Header> <FontAwesomeIcon icon={faShoppingCart} className="cart-item-action mr-2" /> Itens da compra</Card.Header>
                    <Card.Body>
                      <Card.Title className="products-added">
                        {this.props.cart.produtos.length} item(s) adicionado(s)
                      </Card.Title>
                      <Card.Body style={{fontSize: 14}}>
                        <Row>
                          <Col className="text-left checkout-item-price">Subtotal:</Col>
                          <Col className="text-right checkout-item-price">R$ {Diversos.number_format(this.getCartTotal(),2,",","")}</Col>
                        </Row>
                        <Row>
                          <Col className="text-left checkout-item-price">Frete:</Col>
                          <Col className="text-right checkout-item-price">
                            {
                              (this.state.formFormaEntregaPreco !== null) ? 
                              `R$ ${Diversos.number_format(this.state.formFormaEntregaPreco,2,",","")}`
                              : 
                              `--`
                            }
                          </Col>
                        </Row>
                        <Row style={{fontSize: 18, borderTopWidth: 1, borderTopColor: 'red'}} className="mt-4">
                          <Col className="text-left checkout-item-price"><strong> TOTAL: </strong></Col>
                          <Col className="text-right checkout-item-price"><strong>
                          R$ {Diversos.number_format((this.getCartTotal() +
                          ( (this.state.formFormaEntregaPreco !== null) ?
                          this.state.formFormaEntregaPreco : 0 )),2,",","")} </strong></Col>
                        </Row>
                      </Card.Body>
                      <a href="/checkout" target="_self" className="btn btn-primary">Visualizar itens</a>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col>
                  <Card>
                    <Card.Header className="title-forma-pg">
                      <FontAwesomeIcon icon="shopping-cart" className="mr-2"/> Selecione a forma de pagamento
                    </Card.Header>
                    <Accordion defaultActiveKey="0">
                      <Card className="border-0">
                        <Card.Header className="bg-white checkout-accordion-header">
                          <Accordion.Toggle as={Button} variant="link" eventKey="0"
                          onClick={() => this.setState({formFormaPgtoCodigo: 1})}
                          className={(this.state.formFormaPgtoCodigo == 1) ?
                          'selected-option label-forma-pg' : 'label-forma-pg'}>
                            <FontAwesomeIcon icon="credit-card" className="mr-2"/>
                            Cartão de crédito
                          </Accordion.Toggle>
                          {
                            this.state.formFormaPgtoCodigo == 1 &&
                            <small className="selected-option">(selecionado)</small>
                          }
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Row>
                              <Col xs={12} md={6}>
                                <Form method="post" action="#" onSubmit={this.handleForm.bind(this)}>
                                  <Row>
                                    <Col>
                                      <h4 className="pull-right">Total: R$ {Diversos.number_format((this.getCartTotal() + this.state.formFormaEntregaPreco),2,",","")}</h4>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Form.Group controlId="formPagamentoCartaoNumero">
                                        <Form.Label>Número</Form.Label>
                                        <InputMask 
                                          className={"form-control"}
                                          mask="9999 9999 9999 9999" 
                                          // maskPlaceholder="_" 
                                          alwaysShowMask={false} 
                                          name="cartao-numero"
                                          value={this.state.formCartaoNumero}
                                          onChange={(event) => {
                                            this.setState({formCartaoNumero: event.target.value});
                                            if ( Diversos.getnums(event.target.value).length == 6 ) {
                                              this.getCardBrand(event.target.value);
                                            } else if ( Diversos.getnums(event.target.value).length < 6 ) {
                                              this.setState({formCartaoBandeira: null});
                                            }
                                          }}
                                        >
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={12} sm={12}>
                                      <Form.Group controlId="formPagamentoCartaoNome">
                                        <Form.Label>Nome (como no cartão)</Form.Label>
                                        <Form.Control type="text" placeholder="" value={this.state.formCartaoNome} name="cartao-nome" onChange={(event) => this.setState({formCartaoNome: event.target.value})}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <Form.Group controlId="formPagamentoCartaoMesValidade">
                                        <Form.Label>Mês validade</Form.Label>
                                        <InputMask className={"form-control"} mask="99" alwaysShowMask={false} value={this.state.formCartaoValidadeMes} name="cartao-validade-mes" onChange={(event) => this.setState({formCartaoValidadeMes: event.target.value})}>
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>

                                    <Col>
                                      <Form.Group controlId="formPagamentoCartaoAnoValidade">
                                        <Form.Label>Ano validade</Form.Label>
                                        <InputMask className={"form-control"} mask="2099" alwaysShowMask={false} value={this.state.formCartaoValidadeAno} placeholder="AAAA" name="cartao-validade-ano" onChange={(event) => this.setState({formCartaoValidadeAno: event.target.value})}>
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={5}>
                                      <Form.Group controlId="formPagamentoCartaoCvv">
                                        <Form.Label>Código de segurança</Form.Label>
                                        <InputMask className={"form-control"} mask={(this.state.formCartaoCvvSize === 4) ? "9999" : "999"} value={this.state.formCartaoCvv} alwaysShowMask={false} placeholder="" name="cartao-cvv" onChange={(event) => this.setState({formCartaoCvv: event.target.value})}>
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <button type="submit" className="btn btn-block btn-success">Finalizar compra</button>
                                    </Col>
                                  </Row>
                                </Form>
                              </Col>
                              <Col xs={6} className="text-right microinteraction-card">
                                <CreditCard
                                  number={this.state.formCartaoNumero ? this.state.formCartaoNumero : ""}
                                  brand={this.state.formCartaoBandeira ? this.state.formCartaoBandeira : ""}
                                  cardholder={this.state.formCartaoNome ? this.state.formCartaoNome : ""}
                                  expiry={this.state.formCartaoValidadeMes ? `${this.state.formCartaoValidadeMes}/${this.state.formCartaoValidadeAno}` : ""} 
                                  cvv={this.state.formCartaoCvv}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="border-top border-bottom-0 border-right-0 border-left-0">
                        <Card.Header className="checkout-accordion-header">
                          <Accordion.Toggle as={Button} variant="link" eventKey="1"
                          onClick={() => this.setState({formFormaPgtoCodigo: 2})}
                          className={(this.state.formFormaPgtoCodigo == 2) ?
                          'selected-option label-forma-pg' : 'label-forma-pg'}>
                            <FontAwesomeIcon icon="barcode" className="mr-2"/>
                            Boleto bancário
                          </Accordion.Toggle>
                          {
                            this.state.formFormaPgtoCodigo == 2 &&
                            <small className="selected-option">(selecionado)</small>
                          }
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <Form method="post" action="#" onSubmit={this.handleForm.bind(this)}>
                              <Row>
                                <Col>
                                  <h4 className="pull-right">Total: R$ {Diversos.number_format((this.getCartTotal() + this.state.formFormaEntregaPreco),2,",","")}</h4>
                                  <h5 className="important-info">Importante</h5>
                                  <ul className="topicos-boleto">
                                    <li>O boleto expirará após data de vencimento e seu pedido será cancelado automaticamente, sendo necessário refazer seu pedido - sujeito a alteração de valores.</li>
                                    <li>O boleto será exibido logo após a sua confirmação de compra.</li>
                                    <li>O pedido é aprovado em até 2 dias úteis após a realização do pagamento. O prazo para entrega do produto é contado a partir da aprovação.</li>
                                    <li>O pagamento do boleto pode ser efetuado pela internet, utilizando o código de barras, ou diretamente em bancos, lotéricas e correios, apresentando o boleto impresso.</li>
                                  </ul>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <button type="submit" className="btn btn-block btn-success">Finalizar compra</button>
                                </Col>
                              </Row>
                            </Form>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Card>
                </Col>
              </Row>
            </>
          }
        
        </section>
        
        <Footer/>

        <Modal
          show={this.state.formIsLoading}
          onHide={() => null}
          dialogClassName="payment-container"
        >
          <div className="panel">
					  <div className="panel-heading">
              Processando...
            </div>
            <div className="panel-body">
              <div className="mb-3">
                <Lottie 
                  options={{
                    loop: true,
                    autoplay: true, 
                    animationData: processingGif,
                  }}
                  // height={200}
                  width={300}
                  isStopped={false}
                  isPaused={false}
                />
              </div>
              <br/>
              Estamos processando seu pagamento, por favor aguarde a mensagem de conclusão...
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
  order: state.order.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions, ...OrderActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutPagamento);
