import React, { Component } from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import { BrowserRouter, Route, Link, RouteComponentProps, Switch, Redirect } from "react-router-dom";
import {store, persistor} from '../store';
import './App.scss';

import Home from "./Home";
import Login from "./Login";
import Cadastro from "./Cadastro";
import Central from "./Central";
import MeuPerfil from "./MeuPerfil";
import ResetSenha from "./ResetSenha";
import Produto from "./Produto";
import Departamento from "./Departamento";
import Pesquisa from "./Pesquisa";
import NoMatchPage from "./NoMatchPage";
import Checkout from "./Checkout";
import CheckoutPagamento from "./CheckoutPagamento";
import CheckoutFim from "./CheckoutFim";
import MeusPedidos from "./MeusPedidos";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fab, fas);

class App extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }
  render () {
    return ( 
      <Provider store={store}> 
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
            
            {/* DECLARA AS ROTAS USADAS NO PROJETO */}
            <Route path="/" exact component={Home}/>
            <Route path="/login" exact component={Login}/>
            <Route path="/cadastro" exact component={Cadastro}/>
            <Route path="/central-do-cliente" exact component={Central}/>
            <Route path="/meu-perfil" exact component={MeuPerfil}/>
            <Route path="/nova-senha" exact component={ResetSenha}/>
            <Route path="/produto/:nome/:id" exact component={Produto}/>
            <Route path="/departamento/:menu1" exact strict component={Departamento}/>
            <Route path="/departamento/:menu1/:menu2" exact strict component={Departamento}/>
            <Route path="/departamento/:menu1/:menu2/:menu3" exact strict component={Departamento}/>
            <Route path="/busca/:termo" exact strict component={Pesquisa}/>
            <Route path="/checkout" exact strict component={Checkout}/>
            <Route path="/checkout/entrega" exact strict component={CheckoutPagamento}/>
            <Route path="/checkout/fim" exact strict component={CheckoutFim}/>
            <Route path="/meus-pedidos" exact strict component={MeusPedidos}/>
            <Route component={NoMatchPage}/>
            
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );

  }
}

export default App;
