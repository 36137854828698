import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './pages/App';

import 'bootstrap/dist/css/bootstrap.css'; 
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab); 

ReactDOM.render(<App />, document.getElementById('root'));
