import React, { Component, JSXElementConstructor } from 'react';
import { Redirect, Link, useParams } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { compose } from "recompose";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faChevronDown, faChevronUp, faFilter } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import Pagination from "react-js-pagination";
import {Helmet} from "react-helmet";
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import './index.scss';
import FilterSidebar from '../../components/FilterSidebar';
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FilterModal from '../../components/FilterModal';
import Product from '../../components/Product';

interface RouteParams {
  menu1: string
  menu2: string
  menu3: string
}

class Departamento extends Component<{}> {

  api: any = null;
  state: any = {};

  constructor (props) {
    super(props);
    this.api = new Api();
    const {pag} = queryString.parse(props.location.search);
    const {menu1, menu2, menu3} = props.match.params;
    this.state = {
      paramMenu1: menu1,
      paramMenu2: menu2,
      paramMenu3: menu3,
      menu1: {},
      menu2: {},
      menu3: {},
      isLoadingMenu: false,
      isLoadingProdutos: false,
      produtos: [],
      page: (pag) ? pag : 1,
      produtosLastPage: 1,
      produtosPerPage: 24,
      produtosTotal: 1,
    }
  }


  async componentDidMount () {
    const self = this;

    self.getProdutos();

    setTimeout(() => {
      self.setState({ mounted: false });
    }, 1000);
  }

  /**
   * FUNCAO PARA BUSCAR CONFIGURACOES DO MENU ATUAL NA API
   */
  private async handleMenu (menu1, menu2 = null, menu3 = null) {
    const self = this;

    let link: any = null;
    let param: any = {};

    if (( menu1 ) && ( menu2 ) && ( menu3 )) {
      link = `/menu/nivel3-search`;
      param = {menu1: menu1, menu2: menu2, menu3: menu3};
    } else if (( menu1 ) && ( menu2 ) && ( !menu3 )) {
      link = `/menu/nivel2-search`;
      param = {menu1: menu1, menu2: menu2};
    } else if (( menu1 ) && ( !menu2 ) && ( !menu3 )) {
      link = `/menu/nivel1-search`;
      param = {menu1: menu1};
    }

    if ( !link )
      return;

    self.setState({isLoadingMenu: true});

    try {
      const {data} = await self.api.post(link, param);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar dados do menu em ${link}`);
      }

      if (( menu1 ) && ( menu2 ) && ( menu3 )) {
        self.setState({
          menu1: { CDMENU: data.msg.menu2.menu1.CDMENU, DESCRICAO: data.msg.menu2.menu1.DESCRICAO },
          menu2: { CDMENU: data.msg.menu2.CDMENU, DESCRICAO: data.msg.menu2.DESCRICAO },
          menu3: data.msg.menu2.menu3,
          menu3Selected: { CDMENU: data.msg.CDMENU, DESCRICAO: data.msg.DESCRICAO }
        });
      } else if (( menu1 ) && ( menu2 ) && ( !menu3 )) {
        self.setState({
          menu1: { CDMENU: data.msg.menu1.CDMENU, DESCRICAO: data.msg.menu1.DESCRICAO },
          menu2: { CDMENU: data.msg.CDMENU, DESCRICAO: data.msg.DESCRICAO },
          menu3: data.msg.menu3,
          menu3Selected: null
        });
      } else if (( menu1 ) && ( !menu2 ) && ( !menu3 )) {
        self.setState({
          menu1: { CDMENU: data.msg.CDMENU, DESCRICAO: data.msg.DESCRICAO },
          menu2: data.msg.menu2,
          menu3: {},
          menu3Selected: null
        });
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingMenu: false});
    }
  }

  /**
   * FUNCAO PARA BUSCAR LISTA DE PRODUTOS NA API
   */
  private async getProdutos () {
    const self = this;
    const {marcas, preco, pag, sort} = queryString.parse(window.location.search);
    let filtros: any = {};

    if ( marcas )
      filtros = {...filtros, marcas};

    if ( preco )
      filtros = {...filtros, preco};

    if ( sort )
      filtros = {...filtros, sort};

    filtros = {...filtros, per_page: self.state.produtosPerPage};

    self.setState({isLoadingProdutos: true});

    let param: any = {
      menu1: self.state.paramMenu1
    }

    if ( self.state.paramMenu2 )
      param.menu2 = self.state.paramMenu2;

    if ( self.state.paramMenu3 )
      param.menu3 = self.state.paramMenu3;

    try {
      const {data} = await self.api.post(`/product/departamento/${self.state.page}?${queryString.stringify(filtros)}`, param);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar produtos`);
      }

      self.setState({
        produtos: data.msg.data,
        produtosLastPage: data.msg.lastPage,
        page: data.msg.page,
        produtosPerPage: data.msg.perPage,
        produtosTotal: data.msg.total,
        marcas: data.marcas ? data.marcas : []
      });

      if (( self.state.paramMenu1 ) && ( self.state.paramMenu2 ) && ( self.state.paramMenu3 ))
        self.handleMenu(data.msg.data[0].MENU1, data.msg.data[0].MENU2, data.msg.data[0].MENU3);
      else if (( self.state.paramMenu1 ) && ( self.state.paramMenu2 ) && ( !self.state.paramMenu3 ))
        self.handleMenu(data.msg.data[0].MENU1, data.msg.data[0].MENU2, null);
      else if (( self.state.paramMenu1 ) && ( !self.state.paramMenu2 ) && ( !self.state.paramMenu3 ))
        self.handleMenu(data.msg.data[0].MENU1, null, null);
      else
        self.setState({isLoadingMenu: false});

    } catch (e) {
      console.error(e);
      self.setState({ produtos: [], isLoadingMenu: false });
    } finally {
      self.setState({isLoadingProdutos: false});
    }
  }

  /**
   * FAZ TRATAMENTO DO LINK PARA REDIRECIONAMENTO DE PAGINACAO
   */
  private handlePagination ( pag: number ) {
    const self = this;
    let link = window.location.pathname;
    const param = queryString.parse(window.location.search);
    const brands = self.state.brandValues;
    const price = self.state.priceValue;

    let newsParam = param;

    if ( brands )
      newsParam = {...newsParam, marcas: brands};

    if ( price )
      newsParam = {...newsParam, preco: price};

    if (( pag <= 1 ) || ( !pag ))
      newsParam = {...newsParam, pag: '1'};
    else if ( pag > self.state.produtosLastPage )
      newsParam = {...newsParam, pag: self.state.produtosLastPage};
    else
      newsParam = {...newsParam, pag: pag.toString()};

    if (( self.state.sort ) && ( self.state.sort !== "default" ))
      newsParam = {...newsParam, sort: self.state.sort};

    window.location.href = queryString.stringifyUrl({url: link, query: newsParam});
  }

  private _henderSEOTags () {
    if ( this.state.isLoading ) {
      return (<></>);
    } else {
      let menu = this.state.menu1.DESCRICAO;
      let menuPath = this.state.menu1.DESCRICAO;

      if (( this.state.menu2 ) && ( this.state.menu2.DESCRICAO )) {
        menu = this.state.menu2.DESCRICAO;
        menuPath += ` / ${menu}`;
      }

      if (( this.state.menu3Selected ) && ( this.state.menu3Selected.DESCRICAO )) {
        menu = this.state.menu3Selected.DESCRICAO;
        menuPath += ` / ${menu}`;
      }

      const title = `${Diversos.capitalize(menu)} - ${process.env.REACT_APP_TITLE}`;
      const url = `${process.env.REACT_APP_URL}/departamento/${this.state.paramMenu1}`;
      const description = `Melhores ofertas de ${Diversos.capitalize(menuPath)} - ${process.env.REACT_APP_TITLE}`;

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index"/>
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />

          {/* FACEBOOK CARDS */}
          <meta property="fb:app_id" content="719113475453514" />
          <meta property="og:site_name" content={title}/>
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website"/>
          <meta property="og:image" content={require(`../../assets/logo.png`)} />
          <meta property="og:url" content={url}/>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
        </Helmet>
      );
    }
  }

  render () {

    const mdDevice = window.innerWidth <= 992;
    const smDevice = window.innerWidth <= 576;
    
    return (
      <>
        <Header/>
        <section className="product-section">

          {/* TITULO DA PAGINA */}
          {
            (this.state.isLoadingMenu) ? 
            <p>Carregando...</p>
            :
            <>
              <h1>{this.state.menu1.DESCRICAO}</h1>
            </> 
          }

          {/* VITRINE DE PRODUTOS */}
          {
            (this.state.isLoadingProdutos) ? 
            <p>Carregando produtos...</p>
            :
            (this.state.produtos.length <= 0) ? 
            <p>Nenhum produto encontrado.</p>
            :
            <Row>
              {
                this.state.produtos.map((row, index) => (
                  <Col xl={2} lg={3} md={4} sm={6} xs={6}>
                    <Product 
                      imageNormal={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                        : 
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      imageOver={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                          (row.FOTOS.length > 1) ? 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                          : 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                        :
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      item={row}
                    />
                  </Col>
                ))
              }
            </Row>
          }

          {/* PAGINACAO */}
          <Row>
            <Col className={"d-flex justify-content-center"}>
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.produtosPerPage}
                totalItemsCount={this.state.produtosTotal}
                pageRangeDisplayed={5}
                onChange={this.handlePagination.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Col>
          </Row>

        </section>
        <Footer/>
        
        {/* Cria modal dos filtros */}
        <Modal
        show={this.state.showModal}
        onHide={() => this.setState({showModal: false})}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-container"
        >
          <FilterModal/>
        </Modal>
      </>
    );
  }
  
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Departamento);
