import React, { Component, JSXElementConstructor } from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { compose } from "recompose";
import moment from "moment";
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import './index.scss';
import Slider from "react-slick";
import ReactDOM from 'react-dom';

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CategoryCard from '../../components/CategoryCard';
import Product from '../../components/Product';
import Testimonial from '../../components/Testimonial';
import MiniBanner from '../../components/MiniBanner';

// import de Imagens/SVG
// import { ReactComponent as Needle } from '../../assets/Icons/needle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import croche from '../../assets/Images/croche-bambu.png'
import resina from '../../assets/Images/resina.png'
import tnt from '../../assets/Images/tnt.png'



// import de Componentes do Bootstrap
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Carousel from 'react-bootstrap/Carousel'
import Dropdown from 'react-bootstrap/Dropdown'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'


// Row de 4 produtos para vitrine de produtos
const productsRow = (
  <div className="products-wrapper">
    {/* <Row className="justify-content-center">
      <Col lg={3} md={6}>
        <Product imageNormal={require("../../assets/Images/needles.png")}
        imageOver={require("../../assets/Images/needles.png")} />
      </Col>
      <Col lg={3} md={6}>
        <Product imageNormal={require("../../assets/Images/needles.png")}
        imageOver={require("../../assets/Images/needles.png")} />
      </Col>
      <Col lg={3} md={6}>
        <Product imageNormal={require("../../assets/Images/needles.png")}
        imageOver={require("../../assets/Images/needles.png")} />
      </Col>
      <Col lg={3} md={6}>
        <Product imageNormal={require("../../assets/Images/needles.png")}
        imageOver={require("../../assets/Images/needles.png")} />
      </Col>
    </Row> */}
  </div>
);

interface StateProps { 
  cart?: Cart;
  param: Param;
} 

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

interface OwnProps {
  imageOver: any,
  imageNormal: any,
} 

type Props = StateProps & DispatchProps & OwnProps;
 
class Produto extends Component<Props> {

  api: any = null;
  state: any = null;
  static:any

  constructor (props) {
    super(props);
    this.api = new Api();
    const {nome, id} = props.match.params;
    this.state = {
      paramNome: nome,
      paramId: id,
      imgSrc: this.props.imageNormal,
      isLoading: true,
      produto: false,
      similarIsLoading: true,
      similarData: null,
      similarHasError: false,
      similarHasErrorMsg: null,
      quantidade: 1
    }
  }

  async componentDidMount () {
    const self = this;

    if (( !self.state.paramNome ) || ( !self.state.paramId )) {
      window.location.href = "/404";
      return;
    }

    self.setState({isLoading: true});

    try {
      const {data} = await self.api.get(`/product/${self.state.paramId}`);

      if ( !data.status ) {
        throw new Error('Produto não localizado');
      } else {
        self.setState({produto: data.msg});

        if (( data.msg.COMPLEMENTO ) && ( data.msg.COMPLEMENTO.MENU1 )) {
          let paramMenu1 = {menu1: data.msg.COMPLEMENTO.MENU1};
          const response = await self.api.post(`/menu/nivel1-search`, paramMenu1);

          if ( response.data.status === true ) {
            self.setState({menu1: response.data.msg});
            self.getSimilares();
          }
        }

        if (( data.msg.COMPLEMENTO ) && ( data.msg.COMPLEMENTO.MENU2 )) {
          let paramMenu2 = {
            menu1: data.msg.COMPLEMENTO.MENU1,
            menu2: data.msg.COMPLEMENTO.MENU2
          };
          const response2 = await self.api.post(`/menu/nivel2-search`, paramMenu2);

          if ( response2.data.status === true )
            self.setState({menu2: response2.data.msg});
        }

        if (( data.msg.COMPLEMENTO ) && ( data.msg.COMPLEMENTO.MENU3 )) {
          let paramMenu3 = {
            menu1: data.msg.COMPLEMENTO.MENU1,
            menu2: data.msg.COMPLEMENTO.MENU2,
            menu3: data.msg.COMPLEMENTO.MENU3
          };
          const response3 = await self.api.post(`/menu/nivel3-search`, paramMenu3);

          if ( response3.data.status === true )
            self.setState({menu3: response3.data.msg});
        }
      }

    } catch (e) {
      console.error(e);
      window.location.href = "/404";
    } finally {
      self.setState({isLoading: false});
    }
  }

  createProductDisplay = () => {
    let display: JSX.Element[] = [];
    for (let i = 0; i < 2; i++) {
      display.push(productsRow);
    }
    return display;
  }

  private async handleAddCart (event) {
    const self = this;
    event.preventDefault();
    let preco = ((self.state.produto.PREPRO > 0) && (self.state.produto.PREPRO < self.state.produto.PRECO)) ? self.state.produto.PREPRO : self.state.produto.PRECO;

    self.props.add({
      codigo: self.state.produto.CODIGO,
      nome: self.state.produto.NOME,
      priceOrigin: self.state.produto.PRECO,
      price: preco,
      qty: self.state.quantidade,
      foto: `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.produto.FOTOS[0].link}`,
      estoque: self.state.produto.ESTOQUE,
      peso: self.state.produto.PESO
    });
    self.props.setParam({ ...self.props.param, cartOpened: true });
  }

  private async getSimilares () {
    const self = this;

    self.setState({similarIsLoading: true});

    try {
      let param = {menu1: self.state.menu1.CDMENU};
      const {data} = await self.api.post(`/product/departamento`, param);

      if ( data.status === false ) {
        throw new Error('Não foi possível buscar produtos similares.');
      } else {
        self.setState({similarData: data.msg.data});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        similarData: null,
        similarhasError: true,
        similarHasErrorMsg: e.message
      });

    } finally {
      self.setState({similarIsLoading: false});
    }
  }
  
  private async _handlePlusQtd () {
    const self = this;
    
    if ( self.state.quantidade >= self.state.produto.ESTOQUE ) {
      return;
    }
    
    self.setState({
      quantidade: self.state.quantidade + 1
    });
  }

  private async _handleMinusQtd () {
    const self = this;

    if ( self.state.quantidade <= 1 ) {
      return;
    }

    self.setState({
      quantidade: self.state.quantidade - 1
    });
  }

  render () {
    const productBreakpoints = [
      {
        breakpoint: 1676,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        }
      }
    ];

    const smDevice = window.innerWidth <= 576;
    
    return (
      <>
        <Header/>
        
          {/* <Row className="breadcrumb-container">
            <Breadcrumb>
              <Breadcrumb.Item href="#"> Home </Breadcrumb.Item>
              <Breadcrumb.Item href="#"> Categorias </Breadcrumb.Item>
              <Breadcrumb.Item active> Agulhas </Breadcrumb.Item>
            </Breadcrumb>
          </Row> */}

          {
            (this.state.isLoading) ? 
            <p>Carregando...</p>
            :
            <>
              <section className="product-section">
                <Row className="product-info-container">
                  <Col lg={6} className="image-tag">
                    {/* <Image src={croche} rounded fluid /> */}
                    <Carousel interval={null}>
                      {
                        this.state.produto.FOTOS.map((row, index) => (
                          <Carousel.Item key={index}>
                            <img
                              className="d-block w-100"
                              src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                              alt={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                            />
                          </Carousel.Item>
                        ))
                      }
                    </Carousel>
                  </Col>
                  <Col lg={6} className="product-tags">
                    <h1 className="name-tag">{this.state.produto.NOME}</h1>
                    <p className="brand-tag"> {this.state.produto.MARCA} </p>
                    <Row>
                      <Col lg={6} xs={6}> 
                        <p> Código: <span> {this.state.produto.CODIGO} </span></p>
                      </Col>
                      <Col lg={6} xs={6}>
                        <p> Em estoque: <span> {this.state.produto.ESTOQUE} </span></p>
                      </Col>
                    </Row>
                    
                    {
                      ((this.state.produto.PREPRO > 0) && (this.state.produto.PREPRO < this.state.produto.PRECO)) ?
                      <>
                        <h5 className="price-tag-de"> R$ {Diversos.number_format(this.state.produto.PRECO,2,",","")} </h5>
                        <h3 className="price-tag"> R$ {Diversos.number_format(this.state.produto.PREPRO,2,",","")} </h3>
                      </>
                      :
                      <h3 className="price-tag"> R$ {Diversos.number_format(this.state.produto.PRECO,2,",","")} </h3>
                    }
                    
                    <Container className="mt-5">
                      <Row className="align-items-center amount-container">
                        <Col lg={1} className="d-flex clear-padding amount-controller">
                          <button className="amount-btn" onClick={this._handleMinusQtd.bind(this)}> - </button>
                        </Col>
                        <Col lg={1} className="d-flex clear-padding">
                          <input type="number"
                          className="amount-input"
                          name="quantidade"
                          min="1"
                          max="9999"
                          step="1"
                          defaultValue="1"
                          value={this.state.quantidade}       
                          />
                        </Col>
                        <Col lg={1} className="d-flex clear-padding amount-controller">
                          <button className="amount-btn" onClick={this._handlePlusQtd.bind(this)}> + </button>
                        </Col>
                      </Row>
                      <Row className="buy-container">
                        <Col lg={6} className="pl-0">
                          <Button className="buy-action" size="lg" onClick={this.handleAddCart.bind(this)}>Comprar</Button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </section>
              <div className="product-details">
                <h2 className="details-heading">Detalhes do produto</h2>
                <p>{this.state.produto.COMPLEMENTO.DESCRICAO1}</p>
                <p>{this.state.produto.COMPLEMENTO.DESCRICAO2}</p>
                <p>{this.state.produto.COMPLEMENTO.DESCRICAO3}</p>
              </div>
              {/*<section className="vitrine">*/}
              {/*  {*/}
              {/*    !this.state.similarData ?*/}
              {/*      <></>*/}
              {/*      :*/}
              {/*      this.state.similarIsLoading ?*/}
              {/*        <Row>*/}
              {/*          <Col xs={12} className="text-center p-0">*/}
              {/*            <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>*/}
              {/*          </Col>*/}
              {/*        </Row>*/}
              {/*        :*/}
              {/*        <Row>*/}
              {/*          <h2 style={{fontSize: '1.5rem', letterSpacing: 'normal', textAlign: 'left', color: '#6950a2'}}> Clientes também compraram </h2>*/}
              {/*          <Col xs={12} className="my-5 carousel-products">*/}
              {/*            <Slider*/}
              {/*              dots={false}*/}
              {/*              arrows={true}*/}
              {/*              autoplay={false}*/}
              {/*              infinite={true}*/}
              {/*              speed={500}*/}
              {/*              slidesToShow={5}*/}
              {/*              slidesToScroll={1}*/}
              {/*              rows={1}*/}
              {/*              responsive={productBreakpoints} >*/}
              {/*              {*/}
              {/*                this.state.similarData.map((row, index) => (*/}
              {/*                  ((row.ESTOQUE) && (row.ESTOQUE > 1) && (row.CODIGO !== this.state.produto.CODIGO)) &&*/}
              {/*                  <Product*/}
              {/*                    imageNormal={*/}
              {/*                      ((row.FOTOS) && (row.FOTOS.length > 0)) ?*/}
              {/*                        `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`*/}
              {/*                        :*/}
              {/*                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`*/}
              {/*                    }*/}
              {/*                    imageOver={*/}
              {/*                      ((row.FOTOS) && (row.FOTOS.length > 0)) ?*/}
              {/*                        (row.FOTOS.length > 1) ?*/}
              {/*                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`*/}
              {/*                          :*/}
              {/*                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`*/}
              {/*                        :*/}
              {/*                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`*/}
              {/*                    }*/}
              {/*                    item={row}*/}
              {/*                  />*/}
              {/*                ))*/}
              {/*              }*/}
              {/*            </Slider>*/}
              {/*          </Col>*/}
              {/*        </Row>*/}
              {/*  }*/}
              {/*</section>*/}
            </>
          }
          
        <Footer/>
      </>
    );


  }
  
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Produto);