import React from 'react';
import './index.scss';

import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';

import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';

import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';

import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';

import {OrderType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';

import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import UserSidebar from '../../components/UserSidebar';
import DetailsItem from '../../components/DetailsItem';

import { Table, Button, Row, Col, Image, Accordion, Card, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-solid-svg-icons';

import Prod1 from '../../assets/Images/image-3.png'
import Prod2 from '../../assets/Images/image-2.png'
import Prod3 from '../../assets/Images/image-3.png'
import Oil from '../../assets/Images/oil.svg'
import { isIndexSignatureDeclaration } from 'typescript';

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
}

interface OwnProps {
  item: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class Checkout extends React.Component<Props> {

  api: any = null;
  state: any = null;

  textArea: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      statusPedido: 1,
      showMore: false,
      pedidoAberto: false,
      modalDetails: false,
      copySuccess: '',
      email: "",
      senha: "",
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
      quantity: 1,

      // modalDetails: false,
      accordionCollapse: false,

      pedidos: [],
      // statusPedido: "",
      page: 1,
      total: 1,
      perPage: 1,
      lastPage: 1,

      isLoadingItems: false,
      pedidoCurrent: null,
      pedidoCurrentItems: null,


      pedido: this.props.order,
      // pedido: {
      //   cliente: {
      //     codigo: 1,
      //     nome: "Cliente Teste 1",
      //     apelido: "Cli",
      //     cpf: "000.254.000-29",
      //     sexo: "1",
      //     nascimento: "27/08/1993",
      //     telefone: "(41) 3246-4533",
      //     celular: "(41) 99999-9999",
      //     email: "c65497180118000714803@sandbox.pagseguro.com.br",
      //     cep: "81030-230",
      //     rua: "Rua Amaro de Santa Rita",
      //     numero: "357",
      //     complemento: "Sala 6 e Sala 7",
      //     bairro: "Fanny",
      //     cidade: "Curitiba",
      //     estado: "PR",
      //     referencia: "Em frente ao parque Unipark",
      //     cepent: "81030-230",
      //     ruaent: "Rua Amaro de Santa Rita",
      //     numeroent: "357",
      //     complementoent: "Sala 6 e Sala 7",
      //     bairroent: "Fanny",
      //     cidadeent: "Curitiba",
      //     estadoent: "PR",
      //     referenciaent: "Em frente ao parque Unipark",
      //     responsavelent: null,
      //     cadastro: "2020-06-02 13:59:47",
      //     ativo: "S",
      //     pessoa: "F",
      //     tipo: "C",
      //     atualizado: "2020-06-02 13:59:47"
      //   },
      //   loja: 1,
      //   data: "01/07/2020",
      //   status: 1,
      //   desconto: 0,
      //   repres: 1,
      //   oper: 1,
      //   entrega: "SEDEX",
      //   dtentrega: "03/07/2020",
      //   frete: 14.9,
      //   tipoped: 1,
      //   formapg: 1,
      //   hora: "09:58",
      //   PEDIDO: 94,
      //   AUTORIZACAO: "0",
      //   NSU: "0",
      //   TID: "0",
      //   GATEWAY: "CIELO",
      //   PS_CODE: "65BDAAD4-737B-4783-8AC4-71F4428C4742",
      //   PS_DATE: "2020-07-01T09:58:57.000-03:00",
      //   PS_RATE_AMOUNT: "15.90",
      //   PS_FEE_AMOUNT: "1.03",
      //   PS_GATEWAY: "PagSeguro",
      //   BOLETO_URL: null,
      //   itens: [
      //     {
      //       produto: 3384,
      //       qtd: 1,
      //       valor: 1,
      //       nome: "LIMA MTWO 21MM WP16 701 SORT 1004 A 2506: CART C4 UNID"
      //     },
      //     {
      //       produto: 3384,
      //       qtd: 1,
      //       valor: 1,
      //       nome: "SHAMPOO PHYTOGEN GRAFIT CINZA ESCURO KERT 250ML"
      //     },
      //     {
      //       produto: 3384,
      //       qtd: 2,
      //       valor: 1,
      //       nome: "COLORAÇÃO TINTA COLOR 9.0 LOURO MUITO CLARO KEUNE 60ML"
      //     },
      //     {
      //       produto: 3384,
      //       qtd: 4,
      //       valor: 10,
      //       nome: "SHAMPOO UNID"
      //     },
      //   ],
      //   cartao: [
      //     {   
      //       numero: "1",
      //       validade: "1",
      //       cvv: "1"
      //     }
      //   ]
      // },
      pagseguro: {},

    }
  }

  getOrderItemsAmount (itens: any) {
    let soma = 0;

    for (var i = 0; i < itens.length; i++) {
      soma += itens[i].QTD;
    }

    return soma;
  }

  componentDidMount () {
    const self = this; 

    if (( !self.props.user ) || ( self.props.user.status === false )) { 
      self.setState({redirect: '/login'});
      return -1;
    }

    self.getOrders();
  }

  handleChange = (event) => {
    this.setState({quantity: event.target.value});
  }

  private async getOrders () {
    const self = this;

    self.setState({isLoading: true});

    let param = {
      cliente: self.props.user.codigo,
      page: self.state.page
    }
    
    try {
      const {data} = await self.api.post(`/orders`, param);

      if ( !data.status ) {
        throw new Error(`Nenhum pedido localizado: ${data.msg}`);
      } else {
        self.setState({
          pedidos: data.msg.data,
          page: data.msg.page,
          perPage: data.msg.perPage,
          lastPage: data.msg.lastPage,
          total: data.msg.total,
        });
      }

    } catch (e) {
      console.error(e);

    } finally {
      self.setState({isLoading: false});
    }
  }

  private async getOrderItems (pedido: number) {
    const self = this;

    self.setState({isLoadingItems: true});

    try {
      const {data} = await self.api.get(`/order/${pedido}/items`);

      if ( data.status === false ) {
        throw new Error('Nenhum produto encontrado');
      } else {
        self.setState({
          pedidoCurrentItems: data.msg
        });
      }

    } catch (e) {
      console.error(e);
      self.setState({pedidoCurrentItems: []});
    } finally {
      self.setState({isLoadingItems: false});
    }
  }

  private handleChildClick (e, row) {
    this.setState({modalDetails: !this.state.modalDetails, pedidoCurrent: row}, this.getOrderItems.bind(this, row.PEDIDO))
  }

  // Controla classe aplicada ao status do pedido na tela
  private orderStatus (s) {
    if (s === 8) {
      return 'pedido-cancelado';
    }

    else if (s === 6) {
      return 'pedido-concluido';
    }

    else if (s === 5) {
      return 'pedido-enviado';
    }
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ copySuccess: 'Copiado!' });
  };

  handleTooltipHide () {
    this.setState({
      copySuccess: ''
    });
  }

  private getTotalPedido (pedido: any) {
    let total = 0.00;

    for ( var i = 0; i < pedido.itens.length; i++ ) {
      total += pedido.itens[i].QTD * pedido.itens[i].VALOR;
    }

    return total;
  }

  private getSubTotal (itens: any) {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < itens.length; i++ ) {
      total += itens[i].QTD * itens[i].VALOR;
    }
    return total;
  }


  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header/>

        <section className="meus-pedidos">
          <Row className={`mx-0 meus-pedidos-row ${window.innerWidth <= 992 ? 'flex-column-reverse' : ''}`}>
            
            <UserSidebar/>

            <Col lg={8} xl={9} className="meus-pedidos-col">
              <h2> Meus Pedidos </h2>

              { 
              (!this.state.pedidos || this.state.pedidos.length < 1) ?
                <>
                  <article className="pedidos-vazio">
                    <h4> 0
                      <img src={Oil} alt="Sem Pedidos"/>
                    </h4>
                    <h4> Você ainda não fez nenhum pedido </h4>
                    <p> Navegue por nossa loja e encontre seus produtos favoritos </p>
                  </article>
                </>
              :
                <>
                  <Form.Control as="select" name="filtrar pedidos" className="filtrar-pedidos">
                    <option value="Todos"> Todos os Pedidos </option>
                    <option value="Pendente"> Pendente </option>
                    <option value="Confirmacao"> Aguardando Confirmação </option>
                    <option value="Entregue"> Entregue </option>
                    <option value="Cancelado"> Cancelado </option>
                  </Form.Control>

                  <Accordion defaultActiveKey="0" className="meus-pedidos-container">
                  {
                    this.state.pedidos &&
                    this.state.pedidos.length > 0 && 
                    this.state.pedidos.map((row, index) => (
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({pedidoAberto: !this.state.pedidoAberto})} className="pedido-header">
                          Pedido #{ row.PEDIDO }
                          <FontAwesomeIcon icon={this.state.pedidoAberto ? faChevronDown : faChevronUp}/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body as={Row} className="mx-0 pb-0">
                            <div className="pedido-id">
                              <p> <strong> Itens: </strong> </p> 
                              {
                                row.itens.map((row2, index2) => (
                                  <div key={index2}>
                                    <span className="pedido-qtd" title="Quantidade"> { row2.QTD } </span>
                                    <p style={{fontWeight: 400, fontSize: 14, margin: 0, textTransform: 'lowercase'}}> { row2.produtoDados.NOME } </p>
                                  </div>  
                                ))
                              }
                              <p style={{paddingLeft: 0, fontSize: '.75rem', textTransform: 'uppercase', color: '#aaaaaa', textAlign: 'right', margin: '.5rem 0'}}>
                                { this.getOrderItemsAmount(row.itens) } itens
                              </p>
                            </div>
                            <Col sm={6} className="pl-0">
                              <div className="pedido-item">
                                <p> <strong> Data: </strong> { row.DATA } </p> 
                                <p> <strong> Valor: </strong> R$ {Diversos.number_format((this.getSubTotal(row.itens) + row.FRETE - row.DESCONTO),2,",","")} </p> 
                              </div>
                            </Col>
                            <Col sm={6} className="pl-0">
                              <div className="pedido-item">
                                <p> <strong> Pagamento: </strong>
                                {
                                  row.FORMAPG === 1 ? 
                                    `Cartão de crédito`
                                  :
                                    `Boleto Bancário`
                                }
                                </p> 
                                <p> <strong className={`pedido-status ${(this.orderStatus(row.STATUS))}`}> Status: </strong> { Diversos.getStatusTitulo(row.STATUS) } </p> 
                              </div>
                            </Col>
                            <button style={{paddingLeft: 0, margin: '1rem 0', fontSize: '.75rem', textTransform: 'uppercase', fontWeight: 500}} className="btn-link-action" onClick={() => {this.setState({modalDetails: !this.state.modalDetails, pedidoCurrent: row})}}> Ver detalhes do pedido </button>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))
                  }
                  </Accordion>
                </>
              }

            </Col>
          </Row>
        </section>
        
        <Footer/>

        {
          this.state.pedidoCurrent &&
          <Modal
            show={this.state.modalDetails}
            onHide={() => this.setState({
              modalDetails: !this.state.modalDetails,
              pedidoCurrent: null,
              pedidoCurrentItems: null
            })}
            dialogClassName="detalhes-pedido-dialog"
            className="px-0 detalhes-pedido">
            {
              !this.state.pedidoCurrent ?
                <>
                  <Modal.Header closeButton className="detalhes-pedido-header">
                  <span style={{display: "flex", alignItems: "flex-start"}}>
                    <p> Detalhes da compra </p>
                  </span>
                  </Modal.Header>
                  <Modal.Body className="detalhes-pedido-body">
                    <div className="alert alert-danger">
                      Nenhum pedido selecionado.
                    </div>
                  </Modal.Body>
                </>
                :
                <>
                  <Modal.Header closeButton className="detalhes-pedido-header">
                  <span style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <Modal.Title> Pedido #{this.state.pedidoCurrent.PEDIDO} </Modal.Title>
                    <p> Detalhes da compra </p>
                  </span>
                  </Modal.Header>

                  <Modal.Body className="detalhes-pedido-body">
                    <h6> Dados do pedido </h6>

                    <span className="d-flex align-items-center">
                    <p> <strong> Data: </strong> </p>
                    <p> {this.state.pedidoCurrent.DATA} <small className="mx-1"
                                                               style={{color: '#666'}}> • </small> {this.state.pedidoCurrent.HORA} </p>
                  </span>

                    <span className="d-flex align-items-center">
                    <p> <strong> Forma de pagamento: </strong> </p>
                    <p>
                    {
                      this.state.pedidoCurrent.FORMAPG === 1 ?
                        `Cartão de crédito`
                        :
                        `Boleto Bancário`
                    }
                    </p>
                  </span>

                    <span className="d-flex align-items-center">
                    <p> <strong> Status do pedido: </strong> </p>
                    <p> {Diversos.getStatusTitulo(this.state.pedidoCurrent.STATUS)} </p>
                  </span>

                    <hr className="w-100"/>

                    <h6> Dados da entrega </h6>
                    <span className="d-flex align-items-center">
                    <p> <strong> Modo de envio: </strong> </p>
                    <p> {this.state.pedidoCurrent.ENTREGA} </p>
                  </span>
                    {
                      this.state.pedidoCurrent.ENTREGA !== "RETIRA EM LOJA" &&
                      <>
                      <span className="d-flex align-items-center">
                      <p> <strong> Bairro: </strong> </p>
                      <p> {this.state.pedidoCurrent.clienteDados.BAIRRO} </p>
                      </span>
                      <span className="d-flex align-items-center">
                      <p> <strong> CEP: </strong> </p>
                      <p> {this.state.pedidoCurrent.clienteDados.CEP} </p>
                      </span>
                      <span className="d-flex align-items-center">
                      <p> <strong> Cidade: </strong> </p>
                      <p> {this.state.pedidoCurrent.clienteDados.CIDADE} </p>
                      </span>
                      <span className="d-flex align-items-center">
                      <p> <strong> Prazo de envio: </strong> </p>
                      <p> {this.state.pedidoCurrent.DTENTREGA} </p>
                      </span>
                      </>
                    }
                    
                    {
                      ((this.state.pedidoCurrent.CODCORREIO) && (this.state.pedidoCurrent.CODCORREIO !== "1")) && 
                      <span className="rastreamento">
                        <p> <strong> Código de Rastreamento: </strong> </p>
                        <span> 
                          <textarea 
                            readOnly 
                            ref={(textarea) => this.textArea = textarea} 
                            value={this.state.pedidoCurrent.CODCORREIO}
                            style={{borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0}}
                          > 
                            {this.state.pedidoCurrent.CODCORREIO} 
                          </textarea>
                      
                          {
                            document.queryCommandSupported('copy') &&
                            <OverlayTrigger placement={window.innerWidth > 576 ? 'right' : 'top'} delay={{show: 200, hide: 300}}
                            overlay={ <Tooltip id="tooptip-feedback"> { this.state.copySuccess || "Copiar código"} </Tooltip> }>
                              <FontAwesomeIcon icon={faClone} flip="vertical" onClick={this.copyToClipboard}/>
                            </OverlayTrigger>
                          }
                      
                        </span>
                      </span>
                    } 

                    <hr className="w-100"/>

                    <h6> Produtos </h6>
                    {
                      this.state.isLoadingItems ?
                        <i className="fas fa-spin fa-spinner"></i>
                        :
                        ((!this.state.pedidoCurrent.itens) || (this.state.pedidoCurrent.itens.length <= 0)) ?
                          <div className="alert alert-warning">Nenhum produto localizado para este pedido</div>
                          :
                          this.state.pedidoCurrent.itens.map((row, index) => (
                            <DetailsItem item={row} key={index}/>
                          ))
                    }
                  </Modal.Body>

                  <Modal.Footer className="detalhes-pedido-footer">
                    <div className="detalhes-total">
                    <span className="d-flex align-items-center">
                      <p> <strong> Subtotal: </strong> </p>
                      <p> R$ {Diversos.number_format(this.getSubTotal(this.state.pedidoCurrent.itens), 2, ",", "")} </p>
                    </span>
                      <span className="d-flex align-items-center">
                      <p> <strong> Frete: </strong> </p>
                      <p> R$ {Diversos.number_format(this.state.pedidoCurrent.FRETE, 2, ",", "")} </p>
                    </span>
                      <span className="d-flex align-items-center">
                      <p> <strong> Desconto: </strong> </p>
                      <p> R$ {Diversos.number_format(this.state.pedidoCurrent.DESCONTO, 2, ",", "")} </p>
                    </span>
                      <span className="d-flex align-items-center">
                      <p> <strong> Total da compra: </strong> </p>
                      <p> R$ {Diversos.number_format((this.getSubTotal(this.state.pedidoCurrent.itens) + this.state.pedidoCurrent.FRETE - this.state.pedidoCurrent.DESCONTO), 2, ",", "")} </p>
                    </span>
                    </div>

                    <Button variant="secondary" onClick={() => this.setState({
                      modalDetails: !this.state.modalDetails,
                      pedidoCurrent: null,
                      pedidoCurrentItems: null
                    })}> Fechar </Button>

                  </Modal.Footer>
                </>
            }
          </Modal>
        }
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
