import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, CartTypes} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {OrderType, OrderTypes, ItemType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Table, Button, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import './index.scss';

import successGif from '../../assets/animations/success.json'

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  dropOrder(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class CheckoutFim extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: (!this.props.order) ? "/checkout" : null,
      isLoading: false,
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
      pedido: this.props.order
    }
  }

  componentDidMount () {
    const self = this;
    self.props.dropOrder();
  }

  private getSubTotal () {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < self.state.pedido.itens.length; i++ ) {
      total += self.state.pedido.itens[i].qtd * self.state.pedido.itens[i].valor;
    }
    return total;
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header tipo={2}/>

        <section className="content-fluid w-75 mx-auto mb-5">
          <h1>Pedido efetuado!</h1>

          <Row>
            <Col>
              <Card>
                <Card.Header> <FontAwesomeIcon icon="check-circle" className="mr-2" /> Pedido Confirmado</Card.Header>
                <Card.Body className="text-center">
                  <div className="card-1-title text-success mb-2">Seu pedido foi realizado com sucesso!</div>
                  <br/>
                  <Lottie 
                    options={{
                      loop: true,
                      autoplay: true, 
                      animationData: successGif,
                    }}
                    height={150}
                    width={150}
                    isStopped={false}
                    isPaused={false}
                  />
                  <br/>
                  <div className="card-1-text text-dark">O número do seu pedido é:</div>
                  <br/>
                  <div className="card-1-pedido text-dark">{this.state.pedido.pedido}</div>
                  <br/>
                  <div className="card-1-msg text-dark mt-1">
                    Você será notificado no e-mail {this.state.pedido.cliente.email} com todos os detalhes do pedido.
                    <br/>
                    Aguardamos a confirmação do pagamento.
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {
            this.state.pedido.formapg == 2 && 
            <Row className="mt-5">
              <Col>
                <Card>
                  <Card.Header> <FontAwesomeIcon icon="barcode" className="mr-2" /> Boleto bancário</Card.Header>
                  <Card.Body className="text-left">
                    
                    <Row>
                      <Col>
                        <h4>Seu boleto foi gerado com sucesso!</h4>
                        <br/>
                        <table className="table table-sm">
                          <tr>
                            <td colSpan={2} className="text-center py-3">
                              <a href={this.state.pedido.boleto_url} target="_blank" className="btn btn-lg btn-primary">
                                <FontAwesomeIcon icon="print" className="mr-2"/>
                                Clique aqui para imprimir seu boleto
                              </a>
                            </td>
                          </tr>
                        </table>
                      </Col>
                    </Row>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          }

          <Row className="mt-5">
            <Col>
              <Card>
                <Card.Header> <FontAwesomeIcon icon="clipboard-list" className="mr-2" /> Detalhes do pedido</Card.Header>
                <Card.Body className="text-left">
                  
                  <Row>
                    <Col>
                      <span className="card-2-title">Compra realizada em {this.state.pedido.data} ás {this.state.pedido.hora}</span>
                      <br/>
                      <span className="card-2-msg">Comprado por {this.state.pedido.cliente.nome}</span>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col>
                      <h4>Produtos:</h4>
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>Quantidade</th>
                            <th>Preço unitário</th>
                            {/* <th>Desconto</th> */}
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.pedido.itens.map((row, index) => (
                              <tr key={index}>
                                <td>({row.produto}) {row.nome}</td>
                                <td>{row.qtd}</td>
                                <td>R$ {Diversos.number_format(row.valor,2,",",".")}</td>
                                {/* <td>0,00</td> */}
                                <td>R$ {Diversos.number_format((row.valor * row.qtd),2,",",".")}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <h4>Forma de pagamento: </h4>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td>
                              {
                                (this.state.pedido.formapg == 1) ?
                                <span>
                                  Cartão de crédito <br/>
                                  {this.state.pedido.cartao.bandeira} (final ...{this.state.pedido.cartao.numero})
                                </span>
                                :
                                <span>
                                  Boleto bancário <br/>
                                  <a href={this.state.pedido.boleto_url} target="_blank" className="btn btn-sm btn-primary">
                                    <FontAwesomeIcon icon="print" className="mr-2"/>
                                    Imprimir boleto
                                  </a>
                                </span>
                              }
                              
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <h4>Forma de entrega: </h4>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <th>Forma:</th>
                            <td>{this.state.pedido.entrega}</td>
                          </tr>
                          <tr>
                            <th>Prazo:</th>
                            <td>{this.state.pedido.dtentrega}</td>
                          </tr>
                          <tr>
                            <th>Preço:</th>
                            <td>R$ {Diversos.number_format(this.state.pedido.frete,2,",","")}</td>
                          </tr>
                        </tbody>
                      </table>

                      <h4>Endereço de entrega: </h4>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td>
                              {this.state.pedido.cliente.rua}, {this.state.pedido.cliente.numero}, <br/>
                              {this.state.pedido.cliente.complemento} <br/>
                              {this.state.pedido.cliente.bairro} <br/>
                              {this.state.pedido.cliente.cidade} - {this.state.pedido.cliente.estado} <br/>
                              {this.state.pedido.cliente.cep}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col>
                      <h4>Resumo: </h4>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <th>Subtotal</th>
                            <td>R$ {Diversos.number_format(this.getSubTotal(),2,",","")}</td>
                          </tr>
                          <tr>
                            <th>Frete</th>
                            <td>R$ {Diversos.number_format(this.state.pedido.frete,2,",","")}</td>
                          </tr>
                          <tr>
                            <th>TOTAL</th>
                            <td>R$ {Diversos.number_format((this.getSubTotal() + this.state.pedido.frete),2,",","")}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                </Card.Body>

                <Card.Footer className="text-center">
                  <a href="/" target="_self" className="btn btn-outline-primary btn-lg"> <FontAwesomeIcon icon="arrow-left"/> Voltar para loja</a>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          
        </section>
        
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
  order: state.order.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions, ...OrderActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutFim);
