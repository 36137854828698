import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Redirect } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import UserSidebar from '../../components/UserSidebar';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from 'react-input-mask'

import { Button, Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import {Spinner} from 'react-bootstrap'

interface StateProps { 
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

// const { password } = this.state;

class MeuCadastro extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.novoCepRef = React.createRef();
    this.novoRuaRef = React.createRef();
    this.novoNumeroRef = React.createRef();
    this.novoBairroRef = React.createRef();
    this.novoCidadeRef = React.createRef();
    this.novoUfRef = React.createRef();
    this.novoComplementoRef = React.createRef();

    this.state = {
      redirect: null,
      userActiveKey: "pessoal",

      // VARIAVEIS DE CONTROLE PARA FORM DE CADASTRO JA EXISTENTE
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,

      isLoadingCep: false
    }
  }

  componentDidMount () {
    const self = this;

    if (( !self.props.user ) || ( self.props.user.status === false )) { 
      self.setState({redirect: '/login'});
    }

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }
  }

  private async getCustomer () {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status === false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async getAddressByCep () {
    const self = this;

    if ( !self.state.novoCep )
      return false;

    self.setState({isLoadingCep: true});

    let param = {cep : self.state.novoCep}

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if ( data.status ) {
        self.setState(
          {
            customer: {
              ...this.state.customer, 
              rua: data.msg.logradouro,
              bairro: data.msg.bairro,
              cidade: data.msg.localidade,
              estado: data.msg.uf
            }
          }
        );
      }

    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  
  //  FUNCAO PARA TRATAR EDICAO DE CADASTRO

  private async handleSubmit ( tab: number = 0 ) {
    const self = this;
    let link: string = `/customer/${self.props.user.codigo}`;
    let param: any = {};

    if ((tab < 1) || (tab > 3))
      return false;

    if ( tab === 1 ) {
      if (( !self.state.customer.email ) || ( !self.state.customer.nome ) || ( !self.state.customer.nascimento ) || ( !self.state.customer.cpf ) || ( !self.state.customer.celular )) {
        self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
        return;
      }

      if ( !Diversos.validateCPF(self.state.customer.cpf) ) {
        self.setMsg( 'error', "CPF inválido", "Necessário informar um CPF válido para continuar.");
        return;
      }

      if ( !Diversos.validateEmail(self.state.customer.email) ) {
        self.setMsg( 'error', "Email inválido", "Necessário informe um email válido para continuar.");
        return;
      }

      param.nome = self.state.customer.nome;
      param.email = self.state.customer.email;
      param.cpf = self.state.customer.cpf;
      param.nascimento = self.state.customer.nascimento;
      param.sexo = self.state.customer.sexo;
      param.celular = self.state.customer.celular;
      param.telefone = self.state.customer.telefone;
    }

    if ( tab === 2 ) {
      if (( !self.state.customer.cep ) || ( !self.state.customer.rua ) || ( !self.state.customer.numero ) || ( !self.state.customer.bairro ) || ( !self.state.customer.cidade ) || ( !self.state.customer.estado )) {
        self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
        return;
      }

      param.cep = self.state.customer.cep;
      param.rua = self.state.customer.rua;
      param.numero = self.state.customer.numero;
      param.complemento = self.state.customer.complemento;
      param.bairro = self.state.customer.bairro;
      param.cidade = self.state.customer.cidade;
      param.estado = self.state.customer.estado;
    }

    if ( tab === 3 ) {
      if (( !self.state.senhaAtual ) || ( !self.state.senhaNova ) || ( !self.state.confSenhaNova )) {
        self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
        return;
      }

      if ( self.state.senhaNova !== self.state.confSenhaNova ) {
        self.setMsg( 'error', "Confirmação da senha inválida", "Senha nova e sua confirmação não são iguais.");
        return;
      }

      param.senha = self.state.senhaAtual;
      param.senhaNova = self.state.senhaNova;
      link = `/customer/${self.props.user.codigo}/troca-senha`;
    }

    self.setState({ isLoading: true });

    try {
      const {data} = await self.api.put(link, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg( 'success', "Sucesso", `Cadastro atualizado com sucesso.`);
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível atualizar cadastro. ${e.message}`);
    } finally {
      self.setState({ isLoading: false });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type 
   * @param title 
   * @param msg 
   */
  private async setMsg ( type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {

      self.setState({
        customerHasError: true,
        customerHasErrorTitle: title,
        customerHasErrorMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasError: false }), timeout)
      });

    } else {

      self.setState({
        customerHasSuccess: true,
        customerHasSuccessTitle: title,
        customerHasSuccessMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
      });
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
      
        <Header/>
        <section className="dados-da-conta" id="trocar-senha">
          <div className="title-container">
            <h1 className="mt-3"> <span className="heading-border"> Meu Cadastro </span> </h1>
            <p className="mt-3"> Todos os campos com * são obrigatórios </p>
          </div>
          <Row className="mx-0">
            <UserSidebar/>
              <Col lg={9} xl={10} className="dados-col">
                <div className="tab-container">
                  {
                    this.state.customerHasSuccess &&
                    <div className="mb-0 alert alert-success">
                      <strong> {this.state.customerHasSuccessTitle} </strong>
                      <br/>
                      {this.state.customerHasSuccessMsg}
                    </div>
                  }

                  {
                    this.state.customerHasError &&
                    <div className="mb-0 alert alert-danger">
                      <strong>{this.state.customerHasErrorTitle}</strong>
                      <br/>
                      {this.state.customerHasErrorMsg}
                    </div>
                  }

                  <Tabs defaultActiveKey={(window.location.hash) ? "perfil" : "pessoal"} id="controlled-tab" className="tab-controller">
                    {/* FORM PARA DADOS PESSOAIS */}
                    <Tab eventKey="pessoal" title="Dados Pessoais" className="w-100 tab-content">
                      {
                        this.state.isLoadingCustomer ? 
                        <Spinner animation="border" className="d-block mx-auto mt-3" role="status" variant="dark" size="sm">
                          <span className="sr-only"> Loading... </span>
                        </Spinner>
                        :
                        <>
                          <Form className="d-flex w-100">
                            <Col sm={12} md={9} xl={7} className="m-auto">
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Nome * </Form.Label>
                                  <Form.Control
                                  className="user-input"
                                  value={this.state.customer.nome || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, nome: event.target.value}})}/>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> CPF * </Form.Label>
                                  <InputMask
                                  mask="999.999.999-99"
                                  value={this.state.customer.cpf || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, cpf: event.target.value}})}>
                                    {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="" className="user-input" required/>}
                                  </InputMask>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Sexo </Form.Label>
                                  <Form.Control
                                  as="select"
                                  className="user-input"
                                  value={this.state.customer.sexo || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, sexo: event.target.value}})}>
                                    <option value={999}> ... </option>
                                    <option value={1}> Feminino </option>
                                    <option value={2}> Masculino </option>
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> {window.innerWidth <= 440 ? 'Nascimento *' : 'Data de Nascimento *'} </Form.Label>
                                  <InputMask
                                  mask="99/99/9999"
                                  value={this.state.customer.nascimento || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, nascimento: event.target.value}})}>
                                    {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="99/99/9999" className="user-input" required/>}
                                  </InputMask>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} xs={12} sm={6}>
                                  <Form.Label className="meu-cadastro-label"> Celular * </Form.Label>
                                  <InputMask
                                  mask="(99) 9 9999-9999"
                                  value={this.state.customer.celular || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, celular: event.target.value}})}>
                                    {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(41) 9-9999-9999" className="user-input" required/>}
                                  </InputMask>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={6}>
                                  <Form.Label className="meu-cadastro-label"> Telefone </Form.Label>
                                  <InputMask
                                  mask="(99) 9 9999-9999"
                                  value={this.state.customer.telefone || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, telefone: event.target.value}})}>
                                    {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(41) 9-9999-9999" className="user-input" required/>}
                                  </InputMask>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Email * </Form.Label>
                                  <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  className="user-input"
                                  value={this.state.customer.email || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, email: event.target.value}})}/>
                                </Form.Group>
                              </Form.Row>
                            </Col>
                          </Form>

                          <div className="user-actions">
                            <Button className="primary-action" type="button" onClick={this.handleSubmit.bind(this, 1)} disabled={this.state.isLoading}>
                              {(this.state.isLoading) ? 'Salvando...' : 'Salvar'}
                            </Button>
                          </div>

                        </>
                      }

                    </Tab>

                    {/* FORM ENDERECO */}
                    <Tab eventKey="endereço" title="Dados de Entrega" className="w-100 tab-content">
                      {
                        this.state.isLoadingCustomer ? 
                        <Spinner animation="border" className="d-block mx-auto mt-3" role="status" variant="dark" size="sm">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                        :
                        <>
                          <Form className="d-flex w-100">
                            <Col sm={12} md={9} xl={7} className="m-auto">
                              <Form.Row>
                                <Form.Group as={Col} xs={12}>
                                  <Form.Label className="meu-cadastro-label"> CEP * </Form.Label>
                                  <InputMask
                                  mask="99.999-999"
                                  value={this.state.customer.cep  || ""}
                                  onChange={(event) => {
                                    // this.setState({customer: {...this.state.customer, cep: event.target.value}})
                                    let tmp = Diversos.getnums(event.target.value);
                                    if ( tmp.length >= 8 )
                                      this.setState({customer: {...this.state.customer, cep: event.target.value}}, this.getAddressByCep.bind(this))
                                    else
                                      this.setState({customer: {...this.state.customer, cep: event.target.value}})
                                  }}> 
                                    {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="00.000-000" className="user-input" required/>} 
                                  </InputMask>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} xs={12} md={8}>
                                  <Form.Label className="meu-cadastro-label"> Rua * </Form.Label>
                                  <Form.Control
                                  placeholder="Avenida Paulista" 
                                  className="user-input"
                                  value={this.state.customer.rua  || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, rua: event.target.value}})}/>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} md={4}>
                                  <Form.Label className="meu-cadastro-label"> Número * </Form.Label>
                                  <Form.Control
                                  className="user-input"
                                  value={this.state.customer.numero  || ""} 
                                  onChange={(event) => this.setState({customer: {...this.state.customer, numero: event.target.value}})}/>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Bairro * </Form.Label>
                                  <Form.Control
                                  className="user-input"
                                  value={this.state.customer.bairro  || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, bairro: event.target.value}})}/>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Cidade * </Form.Label>
                                  <Form.Control 
                                  placeholder="São Paulo"
                                  className="user-input"
                                  value={this.state.customer.cidade  || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, cidade: event.target.value}})}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Estado * </Form.Label>
                                  <Form.Control
                                  as="select"
                                  value={this.state.customer.estado  || ""} 
                                  className="user-input"
                                  onChange={(event) => this.setState({customer: {...this.state.customer, estado: event.target.value}})}>
                                    {
                                      Diversos.getUFs().map((row, index) => (
                                        <option key={index} value={row.value}>{row.label}</option>
                                      ))
                                    }
                                  </Form.Control>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Complemento </Form.Label>
                                  <Form.Control
                                  placeholder="Ap. 7"
                                  className="user-input"
                                  value={this.state.customer.complemento  || ""}
                                  onChange={(event) => this.setState({customer: {...this.state.customer, complemento: event.target.value}})}/>
                                </Form.Group>
                              </Form.Row>
                            </Col>
                          </Form>

                          <div className="user-actions">
                            <Button className="primary-action" type="button" onClick={this.handleSubmit.bind(this, 2)} disabled={this.state.isLoading}>
                            {(this.state.isLoading) ? 'Salvando...' : 'Salvar'}
                            </Button>
                          </div>

                        </>
                      }

                    </Tab>

                    {/* FORM TROCAR SENHA */}
                    <Tab eventKey="perfil" title="Trocar senha" className="w-100 tab-content">
                      {
                        this.state.isLoadingCustomer ? 
                        <Spinner animation="border" className="d-block mx-auto mt-3" role="status" variant="dark" size="sm">
                          <span className="sr-only"> Loading... </span>
                        </Spinner>
                        :
                        <>
                          <Form className="d-flex w-100" id="troca-senha">
                            <Col sm={12} md={9} xl={7} className="m-auto">
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Senha atual </Form.Label>
                                  <Form.Control type="password" placeholder="Sua senha" className="user-input" onChange={(event) => this.setState({senhaAtual: event.target.value})}/>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Senha nova </Form.Label>
                                  <Form.Control type="password" placeholder="Nova senha" className="user-input" onChange={(event) => this.setState({senhaNova: event.target.value})}/>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label className="meu-cadastro-label"> Confirmação senha </Form.Label>
                                  <Form.Control type="password" placeholder="Confirme a nova senha" className="user-input" onChange={(event) => this.setState({confSenhaNova: event.target.value})}/>
                                </Form.Group>
                              </Form.Row>
                            </Col>
                          </Form>

                          <div className="user-actions">
                            <Button className="primary-action" type="button" onClick={this.handleSubmit.bind(this, 3)} disabled={this.state.isLoading}>
                              {(this.state.isLoading) ? 'Salvando...' : 'Salvar'}
                            </Button>
                          </div>

                        </>
                      }

                    </Tab>

                  </Tabs>
                </div>
              </Col>
            </Row>
          
        </section>

        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeuCadastro);