import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart as CartRedux, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import './index.scss';
import {Diversos} from '../../services/diversos';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faPen} from '@fortawesome/free-solid-svg-icons'

import etiqueta from '../../assets/Images/etiqueta.png'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

interface StateProps { 
  cart: CartRedux;
}

interface DispatchProps {
  update(cart: ProductType): void;
  drop(rowid: string): void;
}

interface OwnProps {
  item: any;
} 

type Props = StateProps & DispatchProps & OwnProps;

class CartItem extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render () {
    return (
      <>
        <Row className="cart-item-container">
          <Col xs={4} md={4} className="cart-item-img-container">
            <Image src={this.props.item.foto} thumbnail className="cart-item-img" />
          </Col>
          <Col xs={6} md={7}>
            <Row>
              <p className="cart-item-name"> {this.props.item.nome} </p>
            </Row>
            <Row>
              <p className="cart-item-price"> R$ {Diversos.number_format(this.props.item.price,2,",","")} </p>
            </Row>
            <Row className="cart-item-details">
              {/*<p className="cart-item-detail">Cor: Verde</p>*/}
              {/*<p className="cart-item-detail">Tamanho: 20cm</p>*/}
              <p className="cart-item-detail">Quantidade: {this.props.item.qty}</p>
            </Row>
          </Col>
          <Col xs={2} md={1} className="cart-item-actions">
            <Row>
              <Button variant="link" onClick={() => this.props.drop(this.props.item.rowid)}>
                <FontAwesomeIcon icon={faTrashAlt} className="cart-item-action color-modifier" title="Excluir item" />
              </Button>
            </Row>
            <Row>
              <Button variant="link">
                <FontAwesomeIcon icon={faPen} className="cart-item-action color-modifier" title="Editar item"/>
              </Button>
            </Row>
          </Col>
        </Row>
        <hr/>
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartItem);