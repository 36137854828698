import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import queryString from 'query-string';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import './index.scss';
import logo from '../../assets/logo-nova.png'

import { isScopable } from '@babel/types';
import { isObject } from 'util';
import { Link, Redirect } from 'react-router-dom';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import NavigationItem from '../NavigationItem';
import Cart from '../Cart'

import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faUserCircle, faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons'

// Bootstrap Components
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

declare var window;

interface StateProps { 
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
} 

interface OwnProps {
  searchTermo?:string;
  tipo?:number;
}

type Props = StateProps & DispatchProps & OwnProps;

class Header extends React.Component<Props> {

  api: any = null;
  state: any = null;
  searchbarRef: any;;
  listener: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.searchbarRef = React.createRef();
    this.state = {
      isLoading: false,
      menu: null,
      searchTerm: (this.props.searchTermo) ? this.props.searchTermo : null,
      searchbarHasError: false,
      redirect: null,

      headerStatus: "top",
      sidebarOpened: false,
      options: [
        {
          title: "Navegação",
          content: [{
            id: 1,
            name: "Departamentos",
            children: [{ content: [] }]
          },
            // { id: 2, name: "Promoções" },
            // { id: 3, name: "Ofertas do mês" },
            // { id: 4, name: "Solicitar Orçamento" }
          ]
        },
        {
          title: "SAC",
          content: [
          {
            id: 5,
            name: "Minha conta",
            children: [
              {
                content: [
                  { id: 51, name: "Meus pedidos", to: "/meus-pedidos" },
                  { id: 52, name: "Dados da conta", to: "/central-do-cliente"},
                  // { id: 53, name: "Trocar senha", to: "/nova-senha" }
                ]
              }
            ]
          },
            // { id: 6, name: "Fale conosco" },
            // { id: 7, name: "Nossas lojas" },
            // { id: 8, name: "Blog" },
            // { id: 9, name: "A Dricor" }
          ]
        }
      ]
    }
  }

  async componentDidMount () {
    const self = this;

    self.getMenu();
    
    if (window.pageYOffset >= 90) {
      this.setState({ headerStatus: "" })
    }
  
    this.listener = document.addEventListener("scroll", e => {
      if (document.scrollingElement === null) {
        // alert ('scrollingElement é null');
      } else {
      var scrolled = document.scrollingElement.scrollTop;
        if (scrolled >= 90) {
          if (this.state.headerStatus !== "") {
            this.setState({ headerStatus: "" });
          }
        } else if (this.state.headerStatus !== "top")  
          this.setState({ headerStatus: "top" });
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

  handleSidebarToggle = isOpen => {
    this.setState({ sidebarOpened: isOpen });
  };
  
  private async getMenu () {
    const self = this;
    
    self.setState({isLoading: true});

    try {
      const {data} = await self.api.get('/menu/nivel1');

      if ( data.status === false ) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        let aux: any = [];
        let aux2: any = [];

        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO,
            menu2: data.msg[i].menu2,
          });

          aux2.push({
            id: i,
            name: data.msg[i].DESCRICAO,
            to: "/departamento/" + Diversos.toSeoUrl(data.msg[i].DESCRICAO),
          });
        }

        let tmp = self.state.options;
        tmp[0].content[0].children[0].content = aux2;

        self.setState({ menu: aux, options: tmp });
      }

    } catch (e) {
      console.error(e);
      self.setState({menu: null});
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async handleSearch () {
    const self = this;
    if ( !self.state.searchTerm ) {
      self.searchbarRef.current.focus();
      self.setState({searchbarHasError: true});
    } else {
      // self.setState({redirect: `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}`});
      window.location.href = `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}`;
    }
  }

  private handleClick = itemOptions => {
    if (itemOptions.delivery) {
      this.setState({
        deliveryAdd: true
      });
    }

    if (itemOptions.fbLink) {
      window.open("https://www.facebook.com/", "_blank")
    }

    if (itemOptions.igLink) {
      window.open("https://www.facebook.com/", "_blank")
    }
  };
  
  render () {
    if ( this.state.redirect ) {
      return <Redirect to={this.state.redirect}/>
    }

    const collapseHeader = (this.state.headerStatus !== "top");
    const notLoggedIn = !((this.props.user) && (this.props.user.status === true));
    const mobileDevice = (window.innerWidth < 992);

    return (
      <>
        {
          ((!this.props.tipo) || (this.props.tipo != 2)) ? 
          <>
            <header>
              <Container fluid className={`top-bar ${collapseHeader ? 'header-collapsed' : ''}`}>
                <Row className="w-100 d-flex align-items-center">

                  <Col className="d-none d-lg-flex justify-content-center" xs={4} md={6} lg={(collapseHeader && !mobileDevice) ? 2 : 3}>
                    <a href="/"> <img src={logo} className="logo" alt="logo"/> </a>
                  </Col>
                  <Col className="justify-content-end" style={{display: (collapseHeader && !mobileDevice) ? 'flex' : 'none'}} xs={4} md={3} lg={1}>
                    <FontAwesomeIcon icon={faBars} className="header-icon" onClick={() => this.handleSidebarToggle(true)}/>
                  </Col>

                  <Col className="pr-0 d-flex justify-content-center search-col" xs={12} lg={6}>
                    <InputGroup>
                      <FormControl
                      className="search-input"
                      placeholder="O que você está procurando?"
                      aria-label="O que você está procurando?"
                      aria-describedby="search-bar"
                      type="text"
                      name="searchbar"
                      ref={this.searchbarRef}
                      value={this.state.searchTerm || ""}
                      onChange={(event) => this.setState({searchTerm: event.target.value})}
                      onKeyPress={(event) => event.key === 'Enter' && this.handleSearch()}
                      isInvalid={this.state.searchbarHasError} />
                      <InputGroup.Append>
                        <Button onClick={this.handleSearch.bind(this)} className="btn-search"> <FontAwesomeIcon icon={faSearch}/> </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>

                  <Col className="justify-content-start" style={{display: mobileDevice ? 'flex' : 'none'}} xs={4}>
                    <FontAwesomeIcon icon={faBars} className="header-icon" onClick={() => this.handleSidebarToggle(true)}/>
                  </Col>
                  <Col className="d-flex d-lg-none justify-content-center" xs={4}>
                    <a href="/"> <img src={logo} className="logo" alt="logo"/> </a>
                  </Col>
                  <Col className="justify-content-xl-center justify-content-lg-center justify-content-md-end justify-content-xl-end" xs={4} lg={2}>
                    <div className="icons-container">
                        <a href={notLoggedIn ? "/login" : "/central-do-cliente"}
                        target="_self"
                        title={notLoggedIn ? "Fazer Login" : "Meu Cadastro"}
                        className="icon-wrapper">
                          <FontAwesomeIcon className="header-icon" icon={faUser} />
                          <p> {notLoggedIn ? 'Login' : 'Perfil'} </p>
                        </a>
                      <span className="icon-wrapper" onClick={() => this.props.setParam({ ...this.props.param, cartOpened: true })} >
                        <FontAwesomeIcon className="header-icon" icon={faShoppingCart} title="Meu Carrinho"/>
                        <p> Carrinho </p>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Container>

              <div className="bottom-bar d-none d-sm-none d-md-none d-lg-block d-xl-block" style={{display: collapseHeader ? 'none' : 'flex'}}>
                <ul className="m-0">
                  {
                    ((!this.state.isLoading) && (this.state.menu) && (this.state.menu.length > 8)) &&
                    <li>
                      <a href={`#`} target="_self"> DEPARTAMENTOS </a>
                      <ul>
                        {
                          this.state.menu.slice(0, 8).map((row, index) => {
                            return (
                              <li>
                                <a 
                                  href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`}
                                  target="_self"
                                > 
                                  {row.descricao} 
                                </a>
                              </li>
                            );
                          })
                        }
                      </ul>
                    </li>
                  }

                  {
                    ((!this.state.isLoading) && (this.state.menu)) ? 
                    this.state.menu.slice(0,8).map((row, index) => {
                        return (
                          <li>
                            <a 
                              href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} 
                              target="_self"
                            > 
                              {row.descricao} 
                            </a>
                            {
                              ((row.menu2) && (row.menu2.length > 0)) &&
                              <ul>
                                {
                                  row.menu2.map((row2, index2) => {
                                    if ( row2.DESCRICAO ) {
                                      return (
                                        <li>
                                          <a
                                            href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}`}
                                            target="_self"
                                          >
                                            {row2.DESCRICAO}
                                          </a>
                                        </li>
                                      );
                                    }
                                  })
                                }
                              </ul>
                            }
                          </li>
                        );
                    })
                    :
                    null
                  }
                </ul>
              </div>
            </header>

            
          </>
          :
          <>
            <header>
              <Container fluid className="top-bar checkout-nav">
                <Row className="w-100 d-flex align-items-center">
                  <Col className="d-flex justify-content-center" lg="12" md="12" xs="12">
                    <Link to="/"><img src={logo} className="logo" alt="logo"/></Link>
                  </Col>
                </Row>
              </Container>
            </header>
          </>
        }

        <Modal
          show={this.props.param.cartOpened}
          onHide={() => this.props.setParam({ ...this.props.param, cartOpened: false })}
          dialogClassName="cart-container"
          aria-labelledby="example-custom-modal-styling-title">
            <Cart/>
        </Modal>

        <MultilevelSidebar
        open={this.state.sidebarOpened}
        onToggle={this.handleSidebarToggle}
        options={this.state.options}
        onItemClick={this.handleClick}
        wrapperClassName="sidebar-mobile-menu"
        header={
          <>
            <Container className="sidebar-header">
              <a className="sidebar-user-link" href={notLoggedIn ? "/login" : "/meu-perfil"}
                target="_self" title={notLoggedIn ? "Entre ou Cadastre-se" : "Meu perfil"}>
                <FontAwesomeIcon icon={faUserCircle} className="sidebar-user-icon"></FontAwesomeIcon>
                <span className="sidebar-user-action">
                  <span className="sidebar-user-title"> Meu perfil </span>
                  {notLoggedIn ? <span> Cadastre-se ou faça login </span> : <span> Meu perfil </span>}
                </span>
              </a>
            </Container>
          </>
        }/>

      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);