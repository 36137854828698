import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { faInstagram} from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare} from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'

import Tecworks from '../../assets/Images/tecworks.png'
import logo from '../../assets/logo.png'
import pagseguro from '../../assets/pagseguro-cinza.png'


class Footer extends React.Component <{}> {

  state: any = null;
  
  constructor(props) {
    super(props);

    this.state = {
      newsletterMail: ''
    }
  }

  private async handleNewsletter () {
    // Implementar
  }

  render () {
    return (
        <>
          <footer>
            <div className="newsletter">
              <span> <h3> Inscreva-se para receber atualizações de promoções e novidades </h3> </span>
              <span> <input type="text" placeholder="E-mail" className="insert-email" onChange={(event) => this.setState({newsletterMail: event.target.value})}/> <button onClick={this.handleNewsletter}> Pronto </button> </span>
            </div>
            <div className="footer-content">
                <div className="info-container">
                  <div className="endereco">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon"/>
                    Rua Dr. Bley Zorning, 1320 - Boqueirão
                    CEP 81730-350
                  </div>
                  <div className="numero">
                    <FontAwesomeIcon icon={faWhatsapp} className="footer-icon"/>
                    (41) 98802-6971
                  </div>
                  <div className="entrega">
                    <FontAwesomeIcon icon={faTruck} className="footer-icon"/>
                    Receba por Correios, Motoboy ou Retire na Loja
                  </div>
                  <div className="pagseguro">
                    <img src={pagseguro}/>
                  </div>
                </div>
                <div className="logo-container">
                  <img src={logo}/>
                </div>
                <div className="links-container">
                  <p> Institucional </p>
                  <a href="/sobre"> Sobre a Dricor </a>
                  <a href="/central-do-cliente"> Central do Cliente </a>
                  <a href="/meu-perfil"> Meu Perfil </a>
                  <a href="/meus-pedidos"> Meus Pedidos </a>
                  <a href="/trocas-e-devolucoes"> Política de trocas e devoluções </a>
                  <div className="social">
                    <p> Redes Sociais </p>
                    <a href="https://www.instagram.com/dricorarmarinhos/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="footer-icon"/></a>
                    <a href="https://www.facebook.com/DricorArmarinhos/" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faFacebookSquare} className="footer-icon"/> </a>
                  </div>
                </div>
              </div>
                <p className="copyright">
                  Dricor Comércio de Armarinhos. hrefdos os direitos reservados. 
                  <br/>
                  Desenvolvido por Grupo TecWorks <img src={Tecworks} alt="logo Tecworks"/>
                </p>
          </footer>
        </>
    );
  }
}

export default Footer