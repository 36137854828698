import React from 'react';
import './index.scss';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import signIn from '../../assets/Icons/sign-in.svg';
import logoTransparente from '../../assets/Images/dricor-transparent.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye, faArrowLeft, faArrowRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'



interface StateProps { 
  user: User;
  param: Param;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Login extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      email: "",
      senha: "",
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
      hidden: true
    }

    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount () {
    const self = this;
    
    if (( self.props.user ) && ( self.props.user.status === true )) { // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      self.setState({redirect: '/'});
    }
  }


  // FAZ TRATAMENTO DA SUBMISSAO FORM DE LOGIN
  private async handleSubmit () {
    const self = this;
    let timeout = 5000;

    if ( !this.state.email ) {
      self.setState({
        hasError: true,
        hasErrorTitle: "Formulário incompleto",
        hasErrorMsg: "Por favor, digite seu e-mail para continuar.",
      }, () => {
        setTimeout(() => self.setState({ hasError: false }), timeout)
      });
      return;
    }

    if ( !this.state.senha ) {
      self.setState({
        hasError: true,
        hasErrorTitle: "Formulário incompleto",
        hasErrorMsg: "Por favor, digite sua senha para continuar.",
      }, () => {
        setTimeout(() => self.setState({ hasError: false }), timeout)
      });
      return;
    }

    self.setState({
      isLoading: true,
      hasErrorTitle: false,
      hasErrorMsg: "",
    });

    let param = {
      email: self.state.email,
      senha: self.state.senha
    }

    try {
      const {data} = await self.api.post("/customer/login", param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        self.setState({
          redirect: '/',
          hasError: false,
          hasErrorTitle: '',
          hasErrorMsg: ""
        });
      }

    } catch (e) {
      // console.error(e);
      self.setState({
        hasError: true,
        hasErrorTitle: 'Login inválido',
        hasErrorMsg: "Não foi possível efetuar login, por favor tente novamente",
      } , () => {
        setTimeout(() => self.setState({ hasError: false }), timeout)
      });
    } finally {
      self.setState({ isLoading: false });
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const smDevice = (window.innerWidth <= 576);

    return (
        <>
        <section className="sign-in-session sign-up-session">
          <Row className="mx-0">

            <Col lg={5} className="user-login">
              <div className="cadastro-logo-container">
                <a href="/" title="Voltar para a Home"> <img src={logoTransparente}/> </a>
              </div>
              <h3> Olá, seja bem vindo(a)! </h3>
              <img src={signIn} className="sign-in-illustration"/>
            </Col>  

            <Col lg={7} className="user-form-col sign-in-col">
              <div className="user-form-container sign-in-container">
                <h1 className="text-center"> Login </h1>

                { this.state.hasError &&
                  <div className="alert alert-danger error-msg-container">
                    <strong> {this.state.hasErrorTitle} </strong>
                    <br/>
                    {this.state.hasErrorMsg}
                  </div>
                }

                <Form className="w-100 user-login-form">
                  <Form.Group controlId="formGridEmail" className="user-input-container sign-up-input">
                    <Form.Label> Seu Email </Form.Label>
                    <Form.Control size={smDevice ? undefined : 'lg'} type="email" placeholder="Email" className={`user-input input-border ${((this.state.hasError) && (!this.state.email)) ? 'input-error' : ''}`}
                    value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
                  </Form.Group>
                  <Form.Group className="user-input-container sign-up-input" controlId="sign-in-session">
                    <Form.Label> Sua Senha </Form.Label>
                    <InputGroup>
                      <Form.Control
                      size={smDevice ? undefined : 'lg'}
                      type={this.state.hidden ? "password" : "text"}
                      placeholder="Senha"
                      className={`user-input input-border ${((this.state.hasError) && (!this.state.senha)) ? 'input-error' : ''}`}
                      value={this.state.senha}
                      aria-label="Senha"
                      aria-describedby="Mostrar senha"
                      onChange={(event) => this.setState({senha: event.target.value})} />
                      <InputGroup.Append>
                        <Button variant="outline-secondary" className="visibility" onClick={this.toggleShow}>
                        <FontAwesomeIcon icon={(this.state.hidden) ? faEyeSlash : faEye}/> </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <a href="/recuperar-senha" title="Recuperar Senha" className="forgot-pswd"> Esqueceu sua senha? </a>
                  </Form.Group>
                </Form>

                <span className="actions-btn-wrapper">
                  <input type="submit" value="Entrar" className="btn-primary-action btn-user" title="Entrar" onClick={this.handleSubmit.bind(this)}/>
                </span>

              </div>
              <h3> Não tem uma conta? <a href="/cadastro"> Cadastre-se </a> </h3>
            </Col>
          </Row>
        </section>
        
        <Footer/>
        </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
