import React from 'react';
import './index.scss';

import PageNotFound from '../../assets/Images/page-not-found.png'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Image from 'react-bootstrap/Image'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

class NoMatchPage extends React.Component {
  render () {
    return (
      <>
        <Header />

          <Container fluid className="section-404">
            <h1 className="heading-404"> Parece que essa página não existe </h1>
            <Image src={ PageNotFound }/>
            <Row className="text-404">
              <p className="subheading-404"> É possível que... </p>
              <ol>
                <li className="item-404"> O endereço do site contenha erros de digitação; </li>
                <li className="item-404"> O conteúdo desse endereço tenha sido movido para outro lugar; </li>
                <li className="item-404"> Essa página nunca tenha existido; </li>
                <li className="item-404"> Todas as opções acima; </li>
              </ol>
            </Row>
            <a href="/" className="back-home"> Voltar para a Home </a>
          </Container>

        <Footer />
      </>
    );
  }
}

export default NoMatchPage;

