import React, { Component } from 'react';
import './index.scss';

// import de Componentes do Bootstrap
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import RangeSlider from 'react-bootstrap-range-slider';

const documentWidth = document.documentElement.clientWidth;
const windowWidth = window.innerWidth;
const scrollBarWidth = windowWidth - documentWidth;

const preventContentJump = document.getElementsByClassName('modal-open');

class TooltipBehaviour extends Component {

  state: any = null;

  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
    this.setValue = this.setValue.bind(this);
  }

  setValue(value) {
    this.setState({ value });
  }

  handleChange() {
    
  }

  render () {
    return (
      <>
        <Form className="form-range-container">
          <Form.Group as={Row}>
            <Col className="range-container">
              <RangeSlider
                value={this.state.value}
                onChange={e => this.setValue(e.target.value)}
                min="0"
                max="500"
                step="10"
                tooltip='on'
                tooltipPlacement="top"
                variant="primary"
              />        
            </Col>
          </Form.Group>
        </Form>
      </>
    );
  }
}

class FilterModal extends Component {

  state: any = null;

  constructor(props){
    super(props);
    this.state = {
      btnVariant: 'link'
    }

    this.setBtnVariant = this.setBtnVariant.bind(this);
  }

  setBtnVariant(btnVariant) {
    this.setState({ btnVariant });
  }
  
  render () {
    return (
      <>
        <Modal.Header closeButton className="filter-header">
          <Modal.Title className="filter-title">
            Filtros
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-type">
            <p className="filter-label"> Ordenar como </p>
            <ButtonGroup vertical>
              <Button variant={this.state.btnVariant} onClick={() => { this.setBtnVariant('primary') }}>Button</Button>
              <Button variant={this.state.btnVariant}>Button</Button>
              <Button variant={this.state.btnVariant}>Button</Button>
            </ButtonGroup>
          </div>
          <div className="filter-type">
            <Form>
              <Form.Group controlId="formBasicRange">
                <Form.Label className="filter-label"> Preço </Form.Label>
                {/* <Form.Control type="range" min="0" max="500" step="10" className="filter-range"/> */}
                <Row className="justify-content-center">
                  <p className="base-price"> R$ 0 </p>
                  <TooltipBehaviour/>
                  <p className="base-price"> R$ 500 </p>
                </Row>
              </Form.Group>
            </Form>
          </div>
          <div className="filter-type">
            <p className="filter-label"> Marcas </p>
            <Container className="brand-filter">
              <Row>
                <Col xs="auto">
                  <Button variant="link">Amigurumi</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Macramê</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Euroroma</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Singer</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">NYBC</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Jumbo</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Liza</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Fina</Button>
                </Col>
                <Col xs="auto">
                  <Button variant="link">Cisne</Button>
                </Col>
              </Row>
            </Container>
          </div>


        </Modal.Body>
      </>
    );
  }
}

export default FilterModal;
