import React from 'react';
import './index.scss';
import minibanner from '../../assets/Images/minibanner.png'


const NavigationItem: React.FC = () => {
  return (
    <>
        <div className="minibanner-container">
          <img src={minibanner}/>
        </div>
    </>
  );
}

export default NavigationItem