import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from 'react-input-mask'
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {Spinner} from 'react-bootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar'

import signUp from '../../assets/Icons/sign-up.svg';
import logoPreta from '../../assets/logo.png'
import logoTransparente from '../../assets/Images/dricor-transparent.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye, faArrowLeft, faArrowRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


interface StateProps { 
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Cadastro extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      pswHidden: true,
      confHidden: true,
      
      // VARIAVEIS DE CONTROLE PARA FORM DE NOVO CADASTRO
      isLoadingNovo: false,
      errors: [],
      novoNome: null,
      novoCpf: null,
      novoTelefone: null,
      novoEmail: null,
      novoSenha: null,
      novoConfSenha: null,
      novoCep: null,
      novoRua: null,
      novoNumero: null,
      novoBairro: null,
      novoCidade: null,
      novoEstado: null,
      novoComplemento: null,
      novoHasError: false,
      novoHasErrorTitle: null,
      novoHasErrorMsg: null,
      currentStep: 1,
      email:  '',
      username: '',
      password: '', 
    }

    this.toggleShow = this.toggleShow.bind(this);

  }

  toggleShow(input: number) {
    if (input === 1) {
      this.setState({ pswHidden: !this.state.pswHidden });
      return;
    }

    this.setState({ confHidden: !this.state.confHidden });
  }

  stepStyling (step) {
    if (step === this.state.currentStep) {
      return 'step-active';
    }
    else if (step < this.state.currentStep) {
      return 'step-passed';
    }
    return 'step-inactive';
  }

  private async getAddressByCep () {
    const self = this;

    if ( !self.state.novoCep )
      return false;

    self.setState({isLoadingCep: true});

    let param = {cep : self.state.novoCep}

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if ( data.status ) {
        self.setState({
          novoRua: data.msg.logradouro,
          novoBairro: data.msg.bairro,
          novoCidade: data.msg.localidade,
          novoEstado: data.msg.uf
        });
      }

    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  private async handleSubmitNovo (pressedButton) {
    const self = this;
    let errors: any = [];

    if (pressedButton === 1) {
      this.setState({currentStep: this.state.currentStep - 1});
      return;
    }
      
    if (this.state.currentStep === 1) {
      
      if ( !self.state.novoEmail ) {
        errors.push('email');
      }

      if ( !self.state.novoSenha ) {
        errors.push('novoSenha');
      }

      if ( !self.state.novoConfSenha ) {
        errors.push('novoConfSenha');
      }

      if (errors.length) {
        self.setState({errors});
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return
      }

      if ( self.state.novoSenha !== self.state.novoConfSenha ) {
        self.setState({errors: ['novoSenha', 'novoConfSenha']});
        self.setMsg( 'error', "Confirmação da senha", `A confirmação da senha não confere com a senha informada.`, 2);
        return;
      }

      this.setState({novoHasError: false, currentStep: 2});
      return;
    }
    
    if (this.state.currentStep === 2) {

      if ( !self.state.novoNome ) {
        errors.push('novoNome');
      }

      if ( !self.state.novoCpf ) {
        errors.push('novoCpf');
      }

      if ( !self.state.novoNascimento ) {
        errors.push('novoNascimento');
      }

      if ( !self.state.novoCelular ) {
        errors.push('novoCelular');
      }

      if (errors.length) {
        self.setState({errors});
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return
      }

      if ( !Diversos.validateCPF(self.state.novoCpf) ) {
        self.setState({errors: ['novoCpf']});
        self.setMsg( 'error', "CPF inválido", `Necessário informar um CPF válido para continuar.`, 2);
      }

      this.setState({novoHasError: false, currentStep: 3});
      return;
    }

    if (this.state.currentStep === 3) {

      if ( !self.state.novoCep ) {
        errors.push('novoCep');
      }

      if ( !self.state.novoRua ) {
        errors.push('novoRua');
      }

      if ( !self.state.novoNumero ) {
        errors.push('novoNumero');
      }

      if ( !self.state.novoBairro ) {
        errors.push('novoBairro');
      }

      if ( !self.state.novoCidade ) {
        errors.push('novoCidade');
      }

      if ( !self.state.novoEstado ) {
        errors.push('novoEstado');
      }

      if (errors.length) {
        self.setState({errors});
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }
    }

    self.setState({ novoHasError: false, isLoadingNovo: true });

    let param = {
      email: self.state.novoEmail,
      senha: self.state.novoSenha,
      nome: self.state.novoNome,
      cpf: self.state.novoCpf,
      nascimento: self.state.novoNascimento,
      telefone: self.state.novoTelefone,
      celular: self.state.novoCelular,
      cep: self.state.novoCep,
      rua: self.state.novoRua,
      numero: self.state.novoNumero,
      bairro: self.state.novoBairro,
      cidade: self.state.novoCidade,
      estado: self.state.novoEstado,
      complemento: self.state.novoComplemento
    }

    try {
      const {data} = await self.api.post('/customer', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        setTimeout(() => {
          self.setState({
            redirect: '/',
          });
        }, 400)
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível criar conta. ${e.message}`, 2);
    } finally {
      self.setState({ isLoadingNovo: false });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type 
   * @param title 
   * @param msg 
   * @param form 
   */

  private async setMsg ( type: string, title: string, msg: string, form: number ) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {
      if ( form === 1 ) {
        self.setState({
          customerHasError: true,
          customerHasErrorTitle: title,
          customerHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasError: false, errors: [] }), timeout)
        });
      } else {
        self.setState({
          novoHasError: true,
          novoHasErrorTitle: title,
          novoHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasError: false, errors: [] }), timeout)
        });
      }
    } else {
      if ( form === 1 ) {
        self.setState({
          customerHasSuccess: true,
          customerHasSuccessTitle: title,
          customerHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
        });
      } else {
        self.setState({
          novoHasSuccess: true,
          novoHasSuccessTitle: title,
          novoHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasSuccess: false }), timeout)
        });
      }
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const percentage = (this.state.currentStep === 1) ? 30 : 60;
    const lgDevice = (window.innerWidth <= 1180);

    return (
        <>
        {/* <Header/> */}

        <section className="user-page sign-up-session">
          <Row className="mx-0">

            <Col lg={5} className="user-cadastro">
              <div className="cadastro-logo-container">
                <a href="/" title="Voltar para a Home"> <img src={logoTransparente}/> </a>
              </div>
              <img src={signUp}/>
            </Col>  

            <Col lg={7} className="user-form-col">
              <div className="user-form-container">
                <div className="d-block text-center mx-auto" style={{marginTop: '5rem'}}>
                  <h1> Cadastro </h1>
                  <p> Todos os campos com * são obrigatórios </p>
                </div>

              {
                this.state.novoHasError &&
                <div className="alert alert-danger error-msg-container">
                  <strong> {this.state.novoHasErrorTitle} </strong>
                  <br/>
                  {this.state.novoHasErrorMsg}
                </div>
              }
                
              <div className="progress-container">
                <ProgressBar animated now={percentage} label={`${percentage}%`}/>
              </div>

              <Form className="w-100 user-login-form">
                {
                  (this.state.currentStep !== 1) ?
                    null 
                  :
                    <>
                      <Form.Group controlId="formGridEmail" className="user-input-container sign-up-input">
                        <Form.Label> Seu Email * </Form.Label>
                        <Form.Control size={lgDevice ? undefined : 'lg'} type="email" placeholder="E-mail" className={`user-input input-border ${this.state.errors.includes('email') ? 'input-error' : ''}`}
                        value={this.state.novoEmail || ""} onChange={(event) => this.setState({novoEmail: event.target.value})}/>
                      </Form.Group>
                      <Form.Group className="user-input-container sign-up-input">
                        <Form.Label> Sua Senha * </Form.Label>
                        <InputGroup>
                          <Form.Control
                          size={lgDevice ? undefined : 'lg'}
                          type={this.state.pswHidden ? "password" : "text"}
                          placeholder="Senha"
                          className={`user-input input-border ${(this.state.errors.includes('novoSenha')) ? 'input-error' : ''}`}
                          value={this.state.novoSenha || ""}
                          aria-label="Senha"
                          aria-describedby="Mostrar senha"
                          onChange={(event) => this.setState({novoSenha: event.target.value})} 
                          />
                          <InputGroup.Append>
                            <Button variant="outline-secondary" className="visibility" onClick={() => this.toggleShow(1)}>
                            <FontAwesomeIcon icon={(this.state.pswHidden) ? faEyeSlash : faEye}/> </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="user-input-container sign-up-input">
                        <Form.Label> Confirme sua Senha * </Form.Label>
                        <InputGroup>
                          <Form.Control
                          size={lgDevice ? undefined : 'lg'}
                          type={this.state.confHidden ? "password" : "text"}
                          placeholder="Senha"
                          className={`user-input input-border ${(this.state.errors.includes('novoConfSenha')) ? 'input-error' : ''}`}
                          value={this.state.novoConfSenha || ""}
                          aria-label="Senha"
                          aria-describedby="Senha"
                          onChange={(event) => this.setState({novoConfSenha: event.target.value})} 
                          />
                          <InputGroup.Append>
                            <Button variant="outline-secondary" className="visibility" aria-label="Mostrar senha" onClick={() => this.toggleShow(2)}>
                            <FontAwesomeIcon icon={(this.state.confHidden) ? faEyeSlash : faEye}/> </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </>
                }
                { 
                  (this.state.currentStep !== 2) ?
                    null 
                  :
                    <>
                      <Form.Row className="mx-auto sign-up-input">
                        <Form.Group as={Col} controlId="formGridNome">
                          <Form.Label> Nome *</Form.Label>
                          <Form.Control type="text" placeholder="Nome" className={`user-input input-border ${(this.state.errors.includes('novoNome')) ? 'input-error' : ''}`}
                          value={this.state.novoNome || ""} onChange={(event) => this.setState({novoNome: event.target.value})} required/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="mx-auto sign-up-input">
                        <Form.Group as={Col} controlId="formGridCpf">
                          <Form.Label> CPF *</Form.Label>
                          <InputMask mask="999.999.999-99" defaultValue={this.state.novoCpf || ""} onChange={(event) => this.setState({novoCpf: event.target.value})} >
                          { (inputProps) =>
                            <Form.Control
                            {...inputProps}
                            type="tel"
                            placeholder="000.000.000-00"
                            className={`user-input input-border ${(this.state.errors.includes('novoCpf')) ? 'input-error' : ''}`} required/>
                          }
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="mx-auto sign-up-input">
                        <Form.Group as={Col} controlId="formGridNascimento">
                          <Form.Label> Data de Nascimento * </Form.Label>
                          <InputMask mask="99/99/9999" defaultValue={this.state.novoNascimento || ""} onChange={(event) => this.setState({novoNascimento: event.target.value})} >
                          { (inputProps) =>
                            <Form.Control
                            {...inputProps}
                            type="tel"
                            placeholder="99/99/9999"
                            className={`user-input input-border ${(this.state.errors.includes('novoNascimento')) ? 'input-error' : ''}`} required/>
                          }
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="mx-auto sign-up-input">
                        <Form.Group as={Col} controlId="formGridTelefone">
                          <Form.Label> Celular *</Form.Label>
                          <InputMask mask="(99) 9 9999-9999" defaultValue={this.state.novoCelular || ""} onChange={(event) => this.setState({novoCelular: event.target.value})} >
                          { (inputProps) =>
                            <Form.Control
                            {...inputProps}
                            type="tel"
                            placeholder="(00) 00000-0000"
                            className={`user-input input-border ${(this.state.errors.includes('novoCelular')) ? 'input-error' : ''}`} required/>
                          }
                          </InputMask>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTelefone2" className="input-as-col" xs={12} sm={6}>
                          <Form.Label> Telefone 2 </Form.Label>
                          <InputMask mask="(99) 9 9999-9999" defaultValue={this.state.novoTelefone || ""} onChange={(event) => this.setState({novoTelefone: event.target.value})}>
                          { ( inputProps) =>
                            <Form.Control
                            {...inputProps}
                            type="tel"
                            placeholder="(41) 9-9999-9999"
                            className="user-input input-border"/>
                          }
                          </InputMask>
                        </Form.Group>
                      </Form.Row>
                    </>
                } 

                    { 
                      (this.state.currentStep !== 3) ?
                        null 
                      :
                      <>
                        <Form.Row className="mx-auto sign-up-input">
                          <Form.Group as={Col} controlId="formGridCEP" xs={12}>
                            <Form.Label> CEP * </Form.Label>
                            <InputMask 
                            mask="99.999-999"
                            ref={this.novoCepRef}
                            disabled={this.state.isLoadingCep}
                            value={this.state.novoCep || ""}
                            onChange={(event) => {
                              let tmp = Diversos.getnums(event.target.value);

                              if ( tmp.length >= 8 )
                                this.setState({novoCep: event.target.value}, this.getAddressByCep.bind(this))
                              else
                                this.setState({novoCep: event.target.value})
                            }}>
                            { (inputProps) =>
                              <Form.Control
                              {...inputProps}
                              type="text"
                              placeholder="00.000-000"
                              className={`user-input input-border ${(this.state.errors.includes('novoCep')) ? 'input-error' : ''}`}
                              required/>
                            }
                            </InputMask>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="mx-auto sign-up-input">
                          <Form.Group as={Col} controlId="formGridRua" xs={12} md={8}>
                            <Form.Label> Rua * </Form.Label>
                            <Form.Control placeholder="Avenida Paulista" className={`user-input input-border ${(this.state.errors.includes('novoRua')) ? 'input-error' : ''}`} value={this.state.novoRua || ""} ref={this.novoRuaRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoRua: event.target.value})} required/>
                          </Form.Group>
                          <Form.Group as={Col} className="input-as-col" controlId="formGridNumero" xs={12} md={4}>
                            <Form.Label> Número * </Form.Label>
                            <Form.Control className={`user-input input-border ${(this.state.errors.includes('novoNumero')) ? 'input-error' : ''}`} value={this.state.novoNumero || ""} ref={this.novoNumeroRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoNumero: event.target.value})} required/>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="mx-auto sign-up-input">
                          <Form.Group as={Col} controlId="formGridBairro">
                            <Form.Label> Bairro * </Form.Label>
                            <Form.Control className={`user-input input-border ${(this.state.errors.includes('novoBairro')) ? 'input-error' : ''}`} value={this.state.novoBairro || ""} ref={this.novoBairroRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoBairro: event.target.value})} required/>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="mx-auto sign-up-input">
                          <Form.Group as={Col} controlId="formGridCidade">
                            <Form.Label> Cidade * </Form.Label>
                            <Form.Control placeholder="São Paulo" value={this.state.novoCidade || ""} ref={this.novoCidadeRef} disabled={this.state.isLoadingCep} className={`user-input input-border ${(this.state.errors.includes('novoCidade')) ? 'input-error' : ''}`} onChange={(event) => this.setState({novoCidade: event.target.value})} required/>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridEstado">
                            <Form.Label> Estado * </Form.Label>
                            <Form.Control as="select" className={`user-input input-border ${(this.state.errors.includes('novoEstado')) ? 'input-error' : ''}`} value={this.state.novoEstado || ""} ref={this.novoUfRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoEstado: event.target.value})} required>
                            {
                              Diversos.getUFs().map((row, index) => (
                                <option key={index} value={row.value}> {row.label} </option>
                              ))
                            }
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="mx-auto sign-up-input">
                          <Form.Group as={Col} controlId="formGridComplemento">
                            <Form.Label> Complemento </Form.Label>
                            <Form.Control placeholder="Ap. 7" className="user-input input-border" value={this.state.novoComplemento || ""} onChange={(event) => this.setState({novoComplemento: event.target.value})}/>
                          </Form.Group>
                        </Form.Row>
                      </>
                    } 

                    <Row className="mx-0 multistep-controller">
                      {
                        (this.state.currentStep !== 1)  ?
                        <button
                        className="btn btn-link-action text-center mx-2"
                        type="button"
                        onClick={this.handleSubmitNovo.bind(this, 1)}>
                          <FontAwesomeIcon icon={faArrowLeft} className="mx-1"/> Voltar
                        </button>
                        :
                        null
                      }

                      {
                        (this.state.currentStep < 3) ?
                        <button
                        className="btn btn-link-action mx-2"
                        type="button"
                        onClick={this.handleSubmitNovo.bind(this, 2)}>
                          Próximo <FontAwesomeIcon icon={faArrowRight} className="mx-1" />
                        </button>
                        :
                        null
                      }
                    </Row>
              </Form>

              {
                (this.state.currentStep === 3) ?
                  <button type="submit" className="btn-primary-action btn-user" style={{marginTop: '2rem', marginBottom: '3.5rem'}} title="Cadastrar"
                  onClick={this.handleSubmitNovo.bind(this)} disabled={this.state.isLoadingNovo}>
                    {
                      this.state.isLoadingNovo ?
                      <>
                        <Spinner animation="border" role="status" variant="light" size="sm">
                          <span className="sr-only"> Loading... </span>
                        </Spinner>
                      </>
                      :
                      <> Cadastrar </>
                    }
                  </button>
                :
                null
              }
              </div>
              <h3> <span className="font-size-16"> Ja possui uma conta? </span> <a href="/login"> Faça Login </a> </h3>
            </Col>
          </Row>
        </section>

        <Footer/>
        </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cadastro);
