import React from 'react';
import './index.scss';

import { Redirect, Link } from "react-router-dom";

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// import {MDCTextField} from '@material/textfield';
// const textField = new MDCTextField(document.querySelector('mdc-text-field'));

const Login: React.FC = () => {
    return (
        <>
        <Header/>

        <section className="login-container">
          <div className="form-container">
            <h1>Recuperação de senha</h1>
              <p className="pswd-recovery-text">
                Insira seu endereço de e-mail e enviaremos a você uma senha temporária.
              </p>
            <div className="input-container">
              <span>
                <label>E-mail</label>
                <input type="email" placeholder="Insira seu e-mail" title="Insira seu e-mail"></input>
              </span>
            </div>
            <Link to="/login" className="forgot-pswd">Voltar</Link>
            <input type="submit" value="Enviar"></input>
            <h2>Não tem uma conta? <Link to="/cadastro"> Cadastre-se </Link></h2>
          </div>
        </section>
        
        <Footer/>
        </>
    );
}

export default Login