/**
 * ACTIONS TYPES
 */
export enum CartTypes {
  ADD = '@repositories/ADD',
  DROP = '@repositories/DROP',
  UPDATE = '@repositories/UPDATE',
  CLEAN = '@repositories/CLEAN',
  SET_FRETE = '@repositories/SET_FRETE',
  SET_CUPOM = '@repositories/SET_CUPOM',
}

export interface ProductType {
  rowid?: string;
  codigo: number;
  nome: string;
  priceOrigin?: number;
  price: number;
  qty: number;
  foto?: string;
  estoque?: number;
  peso: number;
}

/**
 * Data types
 */
export interface Cart {
  produtos: Array<ProductType>
  freteSelected: string
  cupomSelected: string
}

/**
 * State types
 */
export interface CartState {
  readonly data: Cart
}