import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Diversos} from '../../services/diversos';
import './index.scss';
import { classDeclaration } from '@babel/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'

interface StateProps { 
  cart?: Cart;
  param: Param;
} 

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

interface OwnProps {
  imageOver: any;
  imageNormal: any;
  item?: any;
} 

type Props = StateProps & DispatchProps & OwnProps;

class Product extends Component<Props> {

  state: any

  constructor(props) {
    super(props);
    this.state = {
      imgSrc: this.props.imageNormal
    }
  }

  handleMouseOver() {
    this.setState({
    imgSrc: this.props.imageOver
    });
  }
  
  handleMouseOut() {
    this.setState({
      imgSrc: this.props.imageNormal
    });
  }

  private async handleAddCart (event) {
    const self = this;
    event.preventDefault();
    
    let preco = ((self.props.item.PREPRO > 0) && (self.props.item.PREPRO < self.props.item.PRECO)) ? self.props.item.PREPRO : self.props.item.PRECO;
    
    self.props.add({
      codigo: self.props.item.CODIGO,
      nome: self.props.item.NOME,
      priceOrigin: self.props.item.PRECO,
      price: preco,
      qty: 1,
      foto: self.state.imgSrc,
      estoque: self.props.item.ESTOQUE,
      peso: self.props.item.PESO
    });
    self.props.setParam({ ...self.props.param, cartOpened: true });
  }

  render () {

    return (
      <>
        <a 
          href={`/produto/${Diversos.toSeoUrl(this.props.item.NOME)}/${this.props.item.CODIGO}`}
          target="_self"
          onMouseOver={this.handleMouseOver.bind(this)}
          onMouseOut={this.handleMouseOut.bind(this)}
          className="product-container" title="Ver detalhes do produto"
        >
          <span className="product-img-wrapper">
            <img src={this.state.imgSrc} className="product-img" alt=""></img>    
          </span>
          <div className="product-info">
            <span>
              <p className="product-name"> {this.props.item.NOME} </p>
              {/*<p className="product-amount"> 6 unidades </p>*/}
            </span>
            <span>
              {
                ((this.props.item.PREPRO > 0) && (this.props.item.PREPRO < this.props.item.PRECO)) ?
                <>
                  <p className="product-price">
                    <small>R$ {Diversos.number_format(this.props.item.PRECO,2,",","")}</small> 
                    <br/>
                    R$ {Diversos.number_format(this.props.item.PREPRO,2,",","")} 
                  </p>
                </>
                :
                <p className="product-price"> R$ {Diversos.number_format(this.props.item.PRECO,2,",","")} </p>
              }
              {/*<p className="purchase-conditions"> Até 3x de R$ 14,20 </p>*/}
            </span>
            <Button variant="primary" onClick={this.handleAddCart.bind(this)}>Carrinho <FontAwesomeIcon icon={faCartPlus}/></Button>
          </div>
        </a>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Product);