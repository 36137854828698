import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import moment from "moment";
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import './index.scss';

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';
import MiniBanner from '../../components/MiniBanner';
import FilterModal from '../../components/FilterModal';

// import de Imagens/SVG
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import banner from '../../assets/Images/banner-black.jpg'
import banner from '../../assets/Images/banner-novo.png'

// import de Componentes do Bootstrap
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Carousel from 'react-bootstrap/Carousel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'

const documentWidth = document.documentElement.clientWidth;
const windowWidth = window.innerWidth;

interface StateProps { 
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

class Home extends Component<StateProps & DispatchProps & OwnProps> {

  api: any = null;
  state: any = null;

  constructor (props) {
    super(props);
    this.api = new Api();
    this.state = {
      showModal: false,
      destaqueHomeIsLoading: true,
      destaqueHomeLimit: 1,
      destaqueHomeProcuts: [],
    };
  }

  componentDidMount () {
    this.getDestaqueHome();
  }

  private async handleContactSubmit (event) {
    event.preventDefault();
    
    const self = this;
    
    self.setState({
      contatoIsLoading: true,
      contatoHasSuccess: null,
      contatoHasError: null,
    });
    
    let param = {
      nome: self.state.contatoNome,
      email: self.state.contatoEmail,
      fone: self.state.contatoFone,
      mensagem: self.state.contatoMsg
    }
    
    try {
      const {data} = await self.api.post(`/contact`, param);
      
      if ( data.status !== true ) {
        throw new Error( data.msg );
      }

      self.setState({contatoHasSuccess: "Mensagem enviada com sucesso!"});
      
    } catch (e) {
      console.error(e.message);
      self.setState({contatoHasError: e.message});
    } finally {
      self.setState({contatoIsLoading: false});
    }
  }

  private async getDestaqueHome () {
    const self = this;

    self.setState({destaqueHomeIsLoading: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/home/${self.state.destaqueHomeLimit}`, param);

      if ( data.status === false ) {
        throw new Error(`Nenhum produto localizado na home: ${data.msg}`);
      } else {
        self.setState({destaqueHomeProcuts: data.msg.data});
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({destaqueHomeIsLoading: false});
    }
  }

  render () {
    return (

      <>
        <Header/>

        <section className="banner-slider">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </section>

        {
          ((this.state.destaqueHomeIsLoading) || (this.state.destaqueHomeProcuts.length > 0)) &&
          <section>
            <h2 className="main-heading section-title">Promoções em destaque</h2>
            <Row className="justify-content-center">
              {
                this.state.destaqueHomeIsLoading ?
                  <Col className={"justify-content-center"}>
                    <FontAwesomeIcon icon={"spinner"} spin={true} className={"text-primary"} size={"3x"}/>
                  </Col>
                :
                this.state.destaqueHomeProcuts.map((row, index) => (
                  <Col xl={2} lg={3} md={4} sm={6} xs={6}>
                    <Product
                      imageNormal={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ?
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                          :
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      imageOver={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ?
                          (row.FOTOS.length > 1) ?
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                            :
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                          :
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      item={row}
                    />
                  </Col>
                ))
              }
            </Row>
          </section>
        }

        <section className="contact-us">
          <span className="contact-us-cta">
            <h2 className="main-heading section-title lets-talk"> Vamos conversar? </h2>
            <p> Entre em contato através do formulário abaixo </p>
          </span>
          <Form className="contact-form" onSubmit={this.handleContactSubmit.bind(this)}>
            {
              this.state.contatoHasError &&
              <Form.Row>
                <Alert variant={"danger"}>
                  {this.state.contatoHasError}
                </Alert>
              </Form.Row>
            }
            {
              this.state.contatoHasSuccess &&
              <Form.Row>
                <Alert variant={"success"}>
                  {this.state.contatoHasSuccess}
                </Alert>
              </Form.Row>
            }
            <Form.Group controlId="formBasicName">
              <Form.Label> Seu nome </Form.Label>
              <Form.Control className="contact-input" type="text" placeholder="Nome" required onChange={(event) => this.setState({contatoNome: event.target.value})} />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label> Seu E-mail </Form.Label>
              <Form.Control className="contact-input" type="email" placeholder="Email" required onChange={(event) => this.setState({contatoEmail: event.target.value})} />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label> Seu telefone </Form.Label>
              <Form.Control className="contact-input" type="tel" placeholder="Telefone" onChange={(event) => this.setState({contatoFone: event.target.value})} />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label> Como podemos ajudar? </Form.Label>
              <Form.Control as="textarea" className="message-box" placeholder="Mensagem" required onChange={(event) => this.setState({contatoMsg: event.target.value})}/>
            </Form.Group>
            <Button className="submit-button" type="submit" disabled={this.state.contatoIsLoading}>
              {
                this.state.contatoIsLoading ?
                `Enviando mensage, por favor aguarde...`
                :  
                `Enviar mensagem`
              }
            </Button>
          </Form>
        </section>

        <section className="minibanners">
          <MiniBanner/>
        </section>
        
        <Footer/>
        
        {/* Cria modal dos filtros */}
        <Modal
        show={this.state.showModal}
        onHide={() => this.setState({showModal: false})}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-container">
          <FilterModal/>
        </Modal>

      </>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
